* {
  box-sizing: border-box; }

html,
body {
  min-height: 100%; }

body {
	background: #fff;
	color:#000 {}
	font-size: $fz;
	font-family: $main-font;
	line-height: 1.4;
	min-width: 320px;
	overflow-x: hidden;
	@include media-breakpoint-down(md) {
		&.overflow {
			overflow: hidden; } }
	@include media-breakpoint-down(lg) {
		&.overflow-bg {
			overflow: hidden;
			&:before {
				content: '';
				background: rgba(16, 12, 68, 0.84);
				width: 100%;
				height: 100%;
				left: 0;
				right: 0;
				bottom: 0;
				top: 126px;
				position: fixed;
				z-index: 20; } } }
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		&.overflow-bg {
			&:before {
				top: 89px; } } } }

// выделение текста
::selection {
	background-color: $color-1;
	color: $white; }

.section {
	margin: 45px 0; }

.text-right {
	text-align: right; }

.text-left {
	text-align: left; }
