// Noto Sans
// @font-face {
//   font-family: 'Noto Sans';
//   src: url('../fonts/NotoSans/hinted-NotoSans-Italic.woff') format('woff');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: 'Kelson Sans RU';
  src: url('../fonts/kelson/KelsonSans-RegularRU.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kelson Sans RU';
  src: url('../fonts/kelson/KelsonSans-LightRU.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kelson Sans RU';
  src: url('../fonts/kelson/KelsonSans-BoldRU.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}