.icon-eye {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
.icon-lock {
  width: 0.8em;
  height: 1em;
  fill: initial;
}
.icon-men {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 1.14em;
  height: 1em;
  fill: initial;
}
.icon-woomen {
  width: 0.71em;
  height: 1em;
  fill: initial;
}
