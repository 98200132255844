// кнопка
.btn {
    position: relative;
    cursor: pointer;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    font-family: $main-font;
    padding: .6em 16px;
    font-size: 14px;
    border-radius: 25px;
    $parent: &;
    transition: all .2s ease;
    box-shadow: 0 5px 20px 0 rgba(5, 5, 5, 0.18);

    &:active {
        transform: scale(0.96);
    }

    &--line-white {
        border: solid 1px #fff;

        svg {
            fill: #fff;
        }

        &:hover {
            background: #fff;
            color: #000;

            svg {
                fill: #000;
            }
        }
    }

    &--line-blue {
        border: 1px solid #3462c1;
        border-radius: 2px;
        padding: .8em 16px;
        font-size: 13px;

        &:hover {
            background: #3462c1;
            color: #fff;

            svg {
                fill: #fff;
            }
        }
    }

    &--gr {
        font-size: 20px;
        font-weight: 600;
        border-radius: 10px;
        padding: .6em 40px;
        color: #fff;
        background-image: linear-gradient(180deg, #efa62f 2%, #fc3b5e 89%);

        &:hover {
            color: #fff;
            background-image: linear-gradient(180deg, #efa62f 2%, #fc3b5e 60%);
        }

    }

    &--gr-sq {
        font-size: 20px;
        font-weight: 600;
        padding: .9em 40px;
        height: auto;
        text-transform: uppercase;
        letter-spacing: 1.02px;
        font-size: 15px;
        color: #fff !important;
        background-image: linear-gradient(275deg, #d40d53, #2a3180);

        &:hover {
            color: #fff;
            background-image: linear-gradient(275deg, #bb0846, #22286d);
        }

    }

    &--bl-line {
        font-size: 20px;
        font-weight: 700;
        border-radius: 0;
        padding: .9em 40px;
        height: auto;
        text-transform: uppercase;
        letter-spacing: 1.02px;
        font-size: 15px;
        line-height: 1;
        color: #000000;
        border: 2px solid #0010ac;

        &:hover {
            color: #fff;
            background: #0010ac;
        }

    }

    svg {
        margin-right: 10px;
    }

    &--full {
        width: 100%;
    }


    &.disabled {
        border: 1px solid rgba(255, 255, 255, 0.55);
        background-color: #ff9400;
        background-image: linear-gradient(to top, #494949 0%, #818181 100%);
        opacity: 0.4;
        cursor: default;
        pointer-events: none;
    }
}