.bvi-icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.bvi-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -.0667em;
}

.bvi-xs {
    font-size: .75em !important;
}

.bvi-sm {
    font-size: .875em !important;
}

.bvi-1x {
    font-size: 1em !important;
}

.bvi-2x {
    font-size: 2em !important;
}

.bvi-3x {
    font-size: 3em !important;
}

.bvi-4x {
    font-size: 4em !important;
}

.bvi-5x {
    font-size: 5em !important;
}

.bvi-6x {
    font-size: 6em !important;
}

.bvi-7x {
    font-size: 7em !important;
}

.bvi-8x {
    font-size: 8em !important;
}

.bvi-9x {
    font-size: 9em !important;
}

.bvi-10x {
    font-size: 10em !important;
}

.bvi-font:before {
    content: "\f031";
}

.bvi-plus:before {
    content: "\f067";
}

.bvi-minus:before {
    content: "\f068";
}

.bvi-circle:before {
    content: "\f111";
}

.bvi-circle-notch:before {
    content: "\f1ce";
}

.bvi-adjust:before {
    content: "\f042";
}

.bvi-volume-up:before {
    content: "\f028";
}

.bvi-volume-off:before {
    content: "\f026";
}

.bvi-cogs:before {
    content: "\f085";
}

.bvi-low-vision:before {
    content: "\f2a8";
}

.bvi-chevron-circle-up:before {
    content: "\f139";
}

.bvi-eye:before {
    content: "\f06e";
}

.bvi-sync-alt:before {
    content: "\f2f1";
}

.bvi-times-circle:before {
    content: "\f057";
}

.bvi-bolt:before {
    content: "\f0e7";
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("../fonts/fa/fa-solid-900.eot");
    src: url("../fonts/fa/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa/fa-solid-900.woff2") format("woff2"), url("../fonts/fa/fa-solid-900.woff") format("woff"), url("../fonts/fa/fa-solid-900.ttf") format("truetype");
}

.bvi-icon {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

/*!
 * Button visually impaired v1.0.8
 */
html:not(.bvi-panel) {
    font-size: 16px !important;
}

html body a.bvi-link {
    padding: 10px 10px;
    font-size: 12px;
    border-color: transparent;
}

html body .bvi {
    font-family: Arial, sans-serif !important;
}

html body .bvi .bvi-btn {
    font-weight: bold !important;
}

html body .bvi .bvi-eye-link {
    position: fixed;
    top: 1rem;
    right: 1rem;
    opacity: 0.75;
    z-index: 999999 !important;
}

html body .bvi .bvi-container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    html body .bvi .bvi-container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    html body .bvi .bvi-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    html body .bvi .bvi-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    html body .bvi .bvi-container {
        max-width: 1140px;
    }
}

html body .bvi .bvi-container-fluid {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

html body .bvi .bvi-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}

html body .bvi .bvi-no-gutters {
    margin-right: 0;
    margin-left: 0;
}

html body .bvi .bvi-no-gutters>.bvi-col,
html body .bvi .bvi-no-gutters>[class*="bvi-col-"] {
    padding-right: 0;
    padding-left: 0;
}

html body .bvi .bvi-col-1,
html body .bvi .bvi-col-2,
html body .bvi .bvi-col-3,
html body .bvi .bvi-col-4,
html body .bvi .bvi-col-5,
html body .bvi .bvi-col-6,
html body .bvi .bvi-col-7,
html body .bvi .bvi-col-8,
html body .bvi .bvi-col-9,
html body .bvi .bvi-col-10,
html body .bvi .bvi-col-11,
html body .bvi .bvi-col-12,
html body .bvi .bvi-col,
html body .bvi .bvi-col-auto,
html body .bvi .bvi-col-sm-1,
html body .bvi .bvi-col-sm-2,
html body .bvi .bvi-col-sm-3,
html body .bvi .bvi-col-sm-4,
html body .bvi .bvi-col-sm-5,
html body .bvi .bvi-col-sm-6,
html body .bvi .bvi-col-sm-7,
html body .bvi .bvi-col-sm-8,
html body .bvi .bvi-col-sm-9,
html body .bvi .bvi-col-sm-10,
html body .bvi .bvi-col-sm-11,
html body .bvi .bvi-col-sm-12,
html body .bvi .bvi-col-sm,
html body .bvi .bvi-col-sm-auto,
html body .bvi .bvi-col-md-1,
html body .bvi .bvi-col-md-2,
html body .bvi .bvi-col-md-3,
html body .bvi .bvi-col-md-4,
html body .bvi .bvi-col-md-5,
html body .bvi .bvi-col-md-6,
html body .bvi .bvi-col-md-7,
html body .bvi .bvi-col-md-8,
html body .bvi .bvi-col-md-9,
html body .bvi .bvi-col-md-10,
html body .bvi .bvi-col-md-11,
html body .bvi .bvi-col-md-12,
html body .bvi .bvi-col-md,
html body .bvi .bvi-col-md-auto,
html body .bvi .bvi-col-lg-1,
html body .bvi .bvi-col-lg-2,
html body .bvi .bvi-col-lg-3,
html body .bvi .bvi-col-lg-4,
html body .bvi .bvi-col-lg-5,
html body .bvi .bvi-col-lg-6,
html body .bvi .bvi-col-lg-7,
html body .bvi .bvi-col-lg-8,
html body .bvi .bvi-col-lg-9,
html body .bvi .bvi-col-lg-10,
html body .bvi .bvi-col-lg-11,
html body .bvi .bvi-col-lg-12,
html body .bvi .bvi-col-lg,
html body .bvi .bvi-col-lg-auto,
html body .bvi .bvi-col-xl-1,
html body .bvi .bvi-col-xl-2,
html body .bvi .bvi-col-xl-3,
html body .bvi .bvi-col-xl-4,
html body .bvi .bvi-col-xl-5,
html body .bvi .bvi-col-xl-6,
html body .bvi .bvi-col-xl-7,
html body .bvi .bvi-col-xl-8,
html body .bvi .bvi-col-xl-9,
html body .bvi .bvi-col-xl-10,
html body .bvi .bvi-col-xl-11,
html body .bvi .bvi-col-xl-12,
html body .bvi .bvi-col-xl,
html body .bvi .bvi-col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
}

html body .bvi .bvi-col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

html body .bvi .bvi-col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

html body .bvi .bvi-col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

html body .bvi .bvi-col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

html body .bvi .bvi-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

html body .bvi .bvi-col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

html body .bvi .bvi-col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

html body .bvi .bvi-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

html body .bvi .bvi-col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

html body .bvi .bvi-col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

html body .bvi .bvi-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

html body .bvi .bvi-col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

html body .bvi .bvi-col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

html body .bvi .bvi-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

html body .bvi .order-first {
    order: -1;
}

html body .bvi .order-last {
    order: 13;
}

html body .bvi .order-0 {
    order: 0;
}

html body .bvi .order-1 {
    order: 1;
}

html body .bvi .order-2 {
    order: 2;
}

html body .bvi .order-3 {
    order: 3;
}

html body .bvi .order-4 {
    order: 4;
}

html body .bvi .order-5 {
    order: 5;
}

html body .bvi .order-6 {
    order: 6;
}

html body .bvi .order-7 {
    order: 7;
}

html body .bvi .order-8 {
    order: 8;
}

html body .bvi .order-9 {
    order: 9;
}

html body .bvi .order-10 {
    order: 10;
}

html body .bvi .order-11 {
    order: 11;
}

html body .bvi .order-12 {
    order: 12;
}

html body .bvi .offset-1 {
    margin-left: 8.33333%;
}

html body .bvi .offset-2 {
    margin-left: 16.66667%;
}

html body .bvi .offset-3 {
    margin-left: 25%;
}

html body .bvi .offset-4 {
    margin-left: 33.33333%;
}

html body .bvi .offset-5 {
    margin-left: 41.66667%;
}

html body .bvi .offset-6 {
    margin-left: 50%;
}

html body .bvi .offset-7 {
    margin-left: 58.33333%;
}

html body .bvi .offset-8 {
    margin-left: 66.66667%;
}

html body .bvi .offset-9 {
    margin-left: 75%;
}

html body .bvi .offset-10 {
    margin-left: 83.33333%;
}

html body .bvi .offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    html body .bvi .bvi-col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    html body .bvi .bvi-col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    html body .bvi .bvi-col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    html body .bvi .bvi-col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    html body .bvi .bvi-col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    html body .bvi .bvi-col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    html body .bvi .bvi-col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    html body .bvi .bvi-col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    html body .bvi .bvi-col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    html body .bvi .bvi-col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    html body .bvi .bvi-col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    html body .bvi .bvi-col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    html body .bvi .bvi-col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    html body .bvi .bvi-col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    html body .bvi .order-sm-first {
        order: -1;
    }

    html body .bvi .order-sm-last {
        order: 13;
    }

    html body .bvi .order-sm-0 {
        order: 0;
    }

    html body .bvi .order-sm-1 {
        order: 1;
    }

    html body .bvi .order-sm-2 {
        order: 2;
    }

    html body .bvi .order-sm-3 {
        order: 3;
    }

    html body .bvi .order-sm-4 {
        order: 4;
    }

    html body .bvi .order-sm-5 {
        order: 5;
    }

    html body .bvi .order-sm-6 {
        order: 6;
    }

    html body .bvi .order-sm-7 {
        order: 7;
    }

    html body .bvi .order-sm-8 {
        order: 8;
    }

    html body .bvi .order-sm-9 {
        order: 9;
    }

    html body .bvi .order-sm-10 {
        order: 10;
    }

    html body .bvi .order-sm-11 {
        order: 11;
    }

    html body .bvi .order-sm-12 {
        order: 12;
    }

    html body .bvi .offset-sm-0 {
        margin-left: 0;
    }

    html body .bvi .offset-sm-1 {
        margin-left: 8.33333%;
    }

    html body .bvi .offset-sm-2 {
        margin-left: 16.66667%;
    }

    html body .bvi .offset-sm-3 {
        margin-left: 25%;
    }

    html body .bvi .offset-sm-4 {
        margin-left: 33.33333%;
    }

    html body .bvi .offset-sm-5 {
        margin-left: 41.66667%;
    }

    html body .bvi .offset-sm-6 {
        margin-left: 50%;
    }

    html body .bvi .offset-sm-7 {
        margin-left: 58.33333%;
    }

    html body .bvi .offset-sm-8 {
        margin-left: 66.66667%;
    }

    html body .bvi .offset-sm-9 {
        margin-left: 75%;
    }

    html body .bvi .offset-sm-10 {
        margin-left: 83.33333%;
    }

    html body .bvi .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    html body .bvi .bvi-col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    html body .bvi .bvi-col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    html body .bvi .bvi-col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    html body .bvi .bvi-col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    html body .bvi .bvi-col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    html body .bvi .bvi-col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    html body .bvi .bvi-col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    html body .bvi .bvi-col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    html body .bvi .bvi-col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    html body .bvi .bvi-col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    html body .bvi .bvi-col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    html body .bvi .bvi-col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    html body .bvi .bvi-col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    html body .bvi .bvi-col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    html body .bvi .order-md-first {
        order: -1;
    }

    html body .bvi .order-md-last {
        order: 13;
    }

    html body .bvi .order-md-0 {
        order: 0;
    }

    html body .bvi .order-md-1 {
        order: 1;
    }

    html body .bvi .order-md-2 {
        order: 2;
    }

    html body .bvi .order-md-3 {
        order: 3;
    }

    html body .bvi .order-md-4 {
        order: 4;
    }

    html body .bvi .order-md-5 {
        order: 5;
    }

    html body .bvi .order-md-6 {
        order: 6;
    }

    html body .bvi .order-md-7 {
        order: 7;
    }

    html body .bvi .order-md-8 {
        order: 8;
    }

    html body .bvi .order-md-9 {
        order: 9;
    }

    html body .bvi .order-md-10 {
        order: 10;
    }

    html body .bvi .order-md-11 {
        order: 11;
    }

    html body .bvi .order-md-12 {
        order: 12;
    }

    html body .bvi .offset-md-0 {
        margin-left: 0;
    }

    html body .bvi .offset-md-1 {
        margin-left: 8.33333%;
    }

    html body .bvi .offset-md-2 {
        margin-left: 16.66667%;
    }

    html body .bvi .offset-md-3 {
        margin-left: 25%;
    }

    html body .bvi .offset-md-4 {
        margin-left: 33.33333%;
    }

    html body .bvi .offset-md-5 {
        margin-left: 41.66667%;
    }

    html body .bvi .offset-md-6 {
        margin-left: 50%;
    }

    html body .bvi .offset-md-7 {
        margin-left: 58.33333%;
    }

    html body .bvi .offset-md-8 {
        margin-left: 66.66667%;
    }

    html body .bvi .offset-md-9 {
        margin-left: 75%;
    }

    html body .bvi .offset-md-10 {
        margin-left: 83.33333%;
    }

    html body .bvi .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    html body .bvi .bvi-col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    html body .bvi .bvi-col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    html body .bvi .bvi-col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    html body .bvi .bvi-col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    html body .bvi .bvi-col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    html body .bvi .bvi-col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    html body .bvi .bvi-col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    html body .bvi .bvi-col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    html body .bvi .bvi-col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    html body .bvi .bvi-col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    html body .bvi .bvi-col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    html body .bvi .bvi-col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    html body .bvi .bvi-col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    html body .bvi .bvi-col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    html body .bvi .order-lg-first {
        order: -1;
    }

    html body .bvi .order-lg-last {
        order: 13;
    }

    html body .bvi .order-lg-0 {
        order: 0;
    }

    html body .bvi .order-lg-1 {
        order: 1;
    }

    html body .bvi .order-lg-2 {
        order: 2;
    }

    html body .bvi .order-lg-3 {
        order: 3;
    }

    html body .bvi .order-lg-4 {
        order: 4;
    }

    html body .bvi .order-lg-5 {
        order: 5;
    }

    html body .bvi .order-lg-6 {
        order: 6;
    }

    html body .bvi .order-lg-7 {
        order: 7;
    }

    html body .bvi .order-lg-8 {
        order: 8;
    }

    html body .bvi .order-lg-9 {
        order: 9;
    }

    html body .bvi .order-lg-10 {
        order: 10;
    }

    html body .bvi .order-lg-11 {
        order: 11;
    }

    html body .bvi .order-lg-12 {
        order: 12;
    }

    html body .bvi .offset-lg-0 {
        margin-left: 0;
    }

    html body .bvi .offset-lg-1 {
        margin-left: 8.33333%;
    }

    html body .bvi .offset-lg-2 {
        margin-left: 16.66667%;
    }

    html body .bvi .offset-lg-3 {
        margin-left: 25%;
    }

    html body .bvi .offset-lg-4 {
        margin-left: 33.33333%;
    }

    html body .bvi .offset-lg-5 {
        margin-left: 41.66667%;
    }

    html body .bvi .offset-lg-6 {
        margin-left: 50%;
    }

    html body .bvi .offset-lg-7 {
        margin-left: 58.33333%;
    }

    html body .bvi .offset-lg-8 {
        margin-left: 66.66667%;
    }

    html body .bvi .offset-lg-9 {
        margin-left: 75%;
    }

    html body .bvi .offset-lg-10 {
        margin-left: 83.33333%;
    }

    html body .bvi .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    html body .bvi .bvi-col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    html body .bvi .bvi-col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    html body .bvi .bvi-col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    html body .bvi .bvi-col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    html body .bvi .bvi-col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    html body .bvi .bvi-col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    html body .bvi .bvi-col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    html body .bvi .bvi-col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    html body .bvi .bvi-col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    html body .bvi .bvi-col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    html body .bvi .bvi-col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    html body .bvi .bvi-col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    html body .bvi .bvi-col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    html body .bvi .bvi-col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    html body .bvi .order-xl-first {
        order: -1;
    }

    html body .bvi .order-xl-last {
        order: 13;
    }

    html body .bvi .order-xl-0 {
        order: 0;
    }

    html body .bvi .order-xl-1 {
        order: 1;
    }

    html body .bvi .order-xl-2 {
        order: 2;
    }

    html body .bvi .order-xl-3 {
        order: 3;
    }

    html body .bvi .order-xl-4 {
        order: 4;
    }

    html body .bvi .order-xl-5 {
        order: 5;
    }

    html body .bvi .order-xl-6 {
        order: 6;
    }

    html body .bvi .order-xl-7 {
        order: 7;
    }

    html body .bvi .order-xl-8 {
        order: 8;
    }

    html body .bvi .order-xl-9 {
        order: 9;
    }

    html body .bvi .order-xl-10 {
        order: 10;
    }

    html body .bvi .order-xl-11 {
        order: 11;
    }

    html body .bvi .order-xl-12 {
        order: 12;
    }

    html body .bvi .offset-xl-0 {
        margin-left: 0;
    }

    html body .bvi .offset-xl-1 {
        margin-left: 8.33333%;
    }

    html body .bvi .offset-xl-2 {
        margin-left: 16.66667%;
    }

    html body .bvi .offset-xl-3 {
        margin-left: 25%;
    }

    html body .bvi .offset-xl-4 {
        margin-left: 33.33333%;
    }

    html body .bvi .offset-xl-5 {
        margin-left: 41.66667%;
    }

    html body .bvi .offset-xl-6 {
        margin-left: 50%;
    }

    html body .bvi .offset-xl-7 {
        margin-left: 58.33333%;
    }

    html body .bvi .offset-xl-8 {
        margin-left: 66.66667%;
    }

    html body .bvi .offset-xl-9 {
        margin-left: 75%;
    }

    html body .bvi .offset-xl-10 {
        margin-left: 83.33333%;
    }

    html body .bvi .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

html body .bvi .bvi-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    html body .bvi .bvi-btn {
        transition: none;
    }
}

html body .bvi .bvi-btn:hover,
html body .bvi .bvi-btn:focus {
    text-decoration: none;
}

html body .bvi .bvi-btn:focus,
html body .bvi .bvi-btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

html body .bvi .bvi-btn.disabled,
html body .bvi .bvi-btn:disabled {
    opacity: 0.65;
}

html body .bvi .bvi-btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

html body .bvi a.bvi-btn.disabled,
html body .bvi fieldset:disabled a.bvi-btn {
    pointer-events: none;
}

html body .bvi .bvi-btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

html body .bvi .bvi-btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

html body .bvi .bvi-btn-primary:focus,
html body .bvi .bvi-btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

html body .bvi .bvi-btn-primary.disabled,
html body .bvi .bvi-btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

html body .bvi .bvi-btn-primary:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-primary:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

html body .bvi .bvi-btn-primary:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-primary:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

html body .bvi .bvi-btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

html body .bvi .bvi-btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

html body .bvi .bvi-btn-secondary:focus,
html body .bvi .bvi-btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

html body .bvi .bvi-btn-secondary.disabled,
html body .bvi .bvi-btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

html body .bvi .bvi-btn-secondary:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-secondary:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

html body .bvi .bvi-btn-secondary:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

html body .bvi .bvi-btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

html body .bvi .bvi-btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

html body .bvi .bvi-btn-success:focus,
html body .bvi .bvi-btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

html body .bvi .bvi-btn-success.disabled,
html body .bvi .bvi-btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

html body .bvi .bvi-btn-success:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-success:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

html body .bvi .bvi-btn-success:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-success:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

html body .bvi .bvi-btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

html body .bvi .bvi-btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

html body .bvi .bvi-btn-info:focus,
html body .bvi .bvi-btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

html body .bvi .bvi-btn-info.disabled,
html body .bvi .bvi-btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

html body .bvi .bvi-btn-info:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-info:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

html body .bvi .bvi-btn-info:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-info:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

html body .bvi .bvi-btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

html body .bvi .bvi-btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

html body .bvi .bvi-btn-warning:focus,
html body .bvi .bvi-btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

html body .bvi .bvi-btn-warning.disabled,
html body .bvi .bvi-btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

html body .bvi .bvi-btn-warning:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-warning:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

html body .bvi .bvi-btn-warning:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-warning:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

html body .bvi .bvi-btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

html body .bvi .bvi-btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

html body .bvi .bvi-btn-danger:focus,
html body .bvi .bvi-btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

html body .bvi .bvi-btn-danger.disabled,
html body .bvi .bvi-btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

html body .bvi .bvi-btn-danger:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-danger:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

html body .bvi .bvi-btn-danger:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-danger:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

html body .bvi .bvi-btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

html body .bvi .bvi-btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

html body .bvi .bvi-btn-light:focus,
html body .bvi .bvi-btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

html body .bvi .bvi-btn-light.disabled,
html body .bvi .bvi-btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

html body .bvi .bvi-btn-light:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-light:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

html body .bvi .bvi-btn-light:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-light:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

html body .bvi .bvi-btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

html body .bvi .bvi-btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

html body .bvi .bvi-btn-dark:focus,
html body .bvi .bvi-btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

html body .bvi .bvi-btn-dark.disabled,
html body .bvi .bvi-btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

html body .bvi .bvi-btn-dark:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-dark:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

html body .bvi .bvi-btn-dark:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-dark:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

html body .bvi .bvi-btn-bvi-white {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
}

html body .bvi .bvi-btn-bvi-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
}

html body .bvi .bvi-btn-bvi-white:focus,
html body .bvi .bvi-btn-bvi-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

html body .bvi .bvi-btn-bvi-white.disabled,
html body .bvi .bvi-btn-bvi-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
}

html body .bvi .bvi-btn-bvi-white:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-bvi-white:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-bvi-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

html body .bvi .bvi-btn-bvi-white:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-bvi-white:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-bvi-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

html body .bvi .bvi-btn-bvi-black {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

html body .bvi .bvi-btn-bvi-black:hover {
    color: #fff;
    background-color: black;
    border-color: black;
}

html body .bvi .bvi-btn-bvi-black:focus,
html body .bvi .bvi-btn-bvi-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

html body .bvi .bvi-btn-bvi-black.disabled,
html body .bvi .bvi-btn-bvi-black:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

html body .bvi .bvi-btn-bvi-black:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-bvi-black:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-bvi-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
}

html body .bvi .bvi-btn-bvi-black:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-bvi-black:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-bvi-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

html body .bvi .bvi-btn-bvi-blue {
    color: #fff;
    background-color: #063462;
    border-color: #063462;
}

html body .bvi .bvi-btn-bvi-blue:hover {
    color: #fff;
    background-color: #04213e;
    border-color: #031b32;
}

html body .bvi .bvi-btn-bvi-blue:focus,
html body .bvi .bvi-btn-bvi-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 52, 98, 0.5);
}

html body .bvi .bvi-btn-bvi-blue.disabled,
html body .bvi .bvi-btn-bvi-blue:disabled {
    color: #fff;
    background-color: #063462;
    border-color: #063462;
}

html body .bvi .bvi-btn-bvi-blue:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-bvi-blue:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-bvi-blue.dropdown-toggle {
    color: #fff;
    background-color: #031b32;
    border-color: #021426;
}

html body .bvi .bvi-btn-bvi-blue:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-bvi-blue:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-bvi-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 52, 98, 0.5);
}

html body .bvi .bvi-btn-bvi-brown {
    color: #fff;
    background-color: #4D4B43;
    border-color: #4D4B43;
}

html body .bvi .bvi-btn-bvi-brown:hover {
    color: #fff;
    background-color: #393731;
    border-color: #32302b;
}

html body .bvi .bvi-btn-bvi-brown:focus,
html body .bvi .bvi-btn-bvi-brown.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 75, 67, 0.5);
}

html body .bvi .bvi-btn-bvi-brown.disabled,
html body .bvi .bvi-btn-bvi-brown:disabled {
    color: #fff;
    background-color: #4D4B43;
    border-color: #4D4B43;
}

html body .bvi .bvi-btn-bvi-brown:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-bvi-brown:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-bvi-brown.dropdown-toggle {
    color: #fff;
    background-color: #32302b;
    border-color: #2b2a25;
}

html body .bvi .bvi-btn-bvi-brown:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-bvi-brown:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-bvi-brown.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 75, 67, 0.5);
}

html body .bvi .bvi-btn-bvi-green {
    color: #212529;
    background-color: #A9E44D;
    border-color: #A9E44D;
}

html body .bvi .bvi-btn-bvi-green:hover {
    color: #212529;
    background-color: #99df2c;
    border-color: #93dd21;
}

html body .bvi .bvi-btn-bvi-green:focus,
html body .bvi .bvi-btn-bvi-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(169, 228, 77, 0.5);
}

html body .bvi .bvi-btn-bvi-green.disabled,
html body .bvi .bvi-btn-bvi-green:disabled {
    color: #212529;
    background-color: #A9E44D;
    border-color: #A9E44D;
}

html body .bvi .bvi-btn-bvi-green:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-bvi-green:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-bvi-green.dropdown-toggle {
    color: #212529;
    background-color: #93dd21;
    border-color: #8cd120;
}

html body .bvi .bvi-btn-bvi-green:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-bvi-green:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-bvi-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(169, 228, 77, 0.5);
}

html body .bvi .bvi-btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
}

html body .bvi .bvi-btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

html body .bvi .bvi-btn-outline-primary:focus,
html body .bvi .bvi-btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

html body .bvi .bvi-btn-outline-primary.disabled,
html body .bvi .bvi-btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-primary:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-primary:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

html body .bvi .bvi-btn-outline-primary:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

html body .bvi .bvi-btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}

html body .bvi .bvi-btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

html body .bvi .bvi-btn-outline-secondary:focus,
html body .bvi .bvi-btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

html body .bvi .bvi-btn-outline-secondary.disabled,
html body .bvi .bvi-btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-secondary:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

html body .bvi .bvi-btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

html body .bvi .bvi-btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
}

html body .bvi .bvi-btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

html body .bvi .bvi-btn-outline-success:focus,
html body .bvi .bvi-btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

html body .bvi .bvi-btn-outline-success.disabled,
html body .bvi .bvi-btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-success:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-success:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

html body .bvi .bvi-btn-outline-success:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

html body .bvi .bvi-btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
}

html body .bvi .bvi-btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

html body .bvi .bvi-btn-outline-info:focus,
html body .bvi .bvi-btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

html body .bvi .bvi-btn-outline-info.disabled,
html body .bvi .bvi-btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-info:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-info:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

html body .bvi .bvi-btn-outline-info:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

html body .bvi .bvi-btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
}

html body .bvi .bvi-btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

html body .bvi .bvi-btn-outline-warning:focus,
html body .bvi .bvi-btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

html body .bvi .bvi-btn-outline-warning.disabled,
html body .bvi .bvi-btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-warning:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-warning:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

html body .bvi .bvi-btn-outline-warning:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

html body .bvi .bvi-btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
}

html body .bvi .bvi-btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

html body .bvi .bvi-btn-outline-danger:focus,
html body .bvi .bvi-btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

html body .bvi .bvi-btn-outline-danger.disabled,
html body .bvi .bvi-btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-danger:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-danger:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

html body .bvi .bvi-btn-outline-danger:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

html body .bvi .bvi-btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

html body .bvi .bvi-btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

html body .bvi .bvi-btn-outline-light:focus,
html body .bvi .bvi-btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

html body .bvi .bvi-btn-outline-light.disabled,
html body .bvi .bvi-btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-light:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-light:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

html body .bvi .bvi-btn-outline-light:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

html body .bvi .bvi-btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}

html body .bvi .bvi-btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

html body .bvi .bvi-btn-outline-dark:focus,
html body .bvi .bvi-btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

html body .bvi .bvi-btn-outline-dark.disabled,
html body .bvi .bvi-btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-dark:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-dark:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

html body .bvi .bvi-btn-outline-dark:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-white {
    color: #ffffff;
    background-color: transparent;
    background-image: none;
    border-color: #ffffff;
}

html body .bvi .bvi-btn-outline-bvi-white:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
}

html body .bvi .bvi-btn-outline-bvi-white:focus,
html body .bvi .bvi-btn-outline-bvi-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-white.disabled,
html body .bvi .bvi-btn-outline-bvi-white:disabled {
    color: #ffffff;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-bvi-white:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-bvi-white:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-bvi-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
}

html body .bvi .bvi-btn-outline-bvi-white:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-bvi-white:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-bvi-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-black {
    color: #000000;
    background-color: transparent;
    background-image: none;
    border-color: #000000;
}

html body .bvi .bvi-btn-outline-bvi-black:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

html body .bvi .bvi-btn-outline-bvi-black:focus,
html body .bvi .bvi-btn-outline-bvi-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-black.disabled,
html body .bvi .bvi-btn-outline-bvi-black:disabled {
    color: #000000;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-bvi-black:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-bvi-black:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-bvi-black.dropdown-toggle {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

html body .bvi .bvi-btn-outline-bvi-black:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-bvi-black:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-bvi-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-blue {
    color: #063462;
    background-color: transparent;
    background-image: none;
    border-color: #063462;
}

html body .bvi .bvi-btn-outline-bvi-blue:hover {
    color: #fff;
    background-color: #063462;
    border-color: #063462;
}

html body .bvi .bvi-btn-outline-bvi-blue:focus,
html body .bvi .bvi-btn-outline-bvi-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 52, 98, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-blue.disabled,
html body .bvi .bvi-btn-outline-bvi-blue:disabled {
    color: #063462;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-bvi-blue:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-bvi-blue:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-bvi-blue.dropdown-toggle {
    color: #fff;
    background-color: #063462;
    border-color: #063462;
}

html body .bvi .bvi-btn-outline-bvi-blue:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-bvi-blue:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-bvi-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 52, 98, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-brown {
    color: #4D4B43;
    background-color: transparent;
    background-image: none;
    border-color: #4D4B43;
}

html body .bvi .bvi-btn-outline-bvi-brown:hover {
    color: #fff;
    background-color: #4D4B43;
    border-color: #4D4B43;
}

html body .bvi .bvi-btn-outline-bvi-brown:focus,
html body .bvi .bvi-btn-outline-bvi-brown.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 75, 67, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-brown.disabled,
html body .bvi .bvi-btn-outline-bvi-brown:disabled {
    color: #4D4B43;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-bvi-brown:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-bvi-brown:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-bvi-brown.dropdown-toggle {
    color: #fff;
    background-color: #4D4B43;
    border-color: #4D4B43;
}

html body .bvi .bvi-btn-outline-bvi-brown:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-bvi-brown:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-bvi-brown.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 75, 67, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-green {
    color: #A9E44D;
    background-color: transparent;
    background-image: none;
    border-color: #A9E44D;
}

html body .bvi .bvi-btn-outline-bvi-green:hover {
    color: #212529;
    background-color: #A9E44D;
    border-color: #A9E44D;
}

html body .bvi .bvi-btn-outline-bvi-green:focus,
html body .bvi .bvi-btn-outline-bvi-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(169, 228, 77, 0.5);
}

html body .bvi .bvi-btn-outline-bvi-green.disabled,
html body .bvi .bvi-btn-outline-bvi-green:disabled {
    color: #A9E44D;
    background-color: transparent;
}

html body .bvi .bvi-btn-outline-bvi-green:not(:disabled):not(.disabled):active,
html body .bvi .bvi-btn-outline-bvi-green:not(:disabled):not(.disabled).active,
.show>html body .bvi .bvi-btn-outline-bvi-green.dropdown-toggle {
    color: #212529;
    background-color: #A9E44D;
    border-color: #A9E44D;
}

html body .bvi .bvi-btn-outline-bvi-green:not(:disabled):not(.disabled):active:focus,
html body .bvi .bvi-btn-outline-bvi-green:not(:disabled):not(.disabled).active:focus,
.show>html body .bvi .bvi-btn-outline-bvi-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(169, 228, 77, 0.5);
}

html body .bvi .bvi-btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}

html body .bvi .bvi-btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

html body .bvi .bvi-btn-link:focus,
html body .bvi .bvi-btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}

html body .bvi .bvi-btn-link:disabled,
html body .bvi .bvi-btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

html body .bvi .bvi-btn-lg,
html body .bvi .bvi-btn-group-lg>.bvi-btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
}

html body .bvi .bvi-btn-sm,
html body .bvi .bvi-btn-group-sm>.bvi-btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0;
}

html body .bvi .bvi-btn-block {
    display: block;
    width: 100%;
}

html body .bvi .bvi-btn-block+.bvi-btn-block {
    margin-top: 0.5rem;
}

html body .bvi input[type="submit"].bvi-btn-block,
html body .bvi input[type="reset"].bvi-btn-block,
html body .bvi input[type="button"].bvi-btn-block {
    width: 100%;
}

html body .bvi .bvi-btn-group,
html body .bvi .bvi-btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

html body .bvi .bvi-btn-group>.bvi-btn,
html body .bvi .bvi-btn-group-vertical>.bvi-btn {
    position: relative;
    flex: 0 1 auto;
}

html body .bvi .bvi-btn-group>.bvi-btn:hover,
html body .bvi .bvi-btn-group-vertical>.bvi-btn:hover {
    z-index: 1;
}

html body .bvi .bvi-btn-group>.bvi-btn:focus,
html body .bvi .bvi-btn-group>.bvi-btn:active,
html body .bvi .bvi-btn-group>.bvi-btn.active,
html body .bvi .bvi-btn-group-vertical>.bvi-btn:focus,
html body .bvi .bvi-btn-group-vertical>.bvi-btn:active,
html body .bvi .bvi-btn-group-vertical>.bvi-btn.active {
    z-index: 1;
}

html body .bvi .bvi-btn-group .bvi-btn+.bvi-btn,
html body .bvi .bvi-btn-group .bvi-btn+.bvi-btn-group,
html body .bvi .bvi-btn-group .bvi-btn-group+.bvi-btn,
html body .bvi .bvi-btn-group .bvi-btn-group+.bvi-btn-group,
html body .bvi .bvi-btn-group-vertical .bvi-btn+.bvi-btn,
html body .bvi .bvi-btn-group-vertical .bvi-btn+.bvi-btn-group,
html body .bvi .bvi-btn-group-vertical .bvi-btn-group+.bvi-btn,
html body .bvi .bvi-btn-group-vertical .bvi-btn-group+.bvi-btn-group {
    margin-left: -1px;
}

html body .bvi .bvi-btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

html body .bvi .bvi-btn-toolbar .input-group {
    width: auto;
}

html body .bvi .bvi-btn-group>.bvi-btn:first-child {
    margin-left: 0;
}

html body .bvi .bvi-btn-group>.bvi-btn:not(:last-child):not(.dropdown-toggle),
html body .bvi .bvi-btn-group>.bvi-btn-group:not(:last-child)>.bvi-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

html body .bvi .bvi-btn-group>.bvi-btn:not(:first-child),
html body .bvi .bvi-btn-group>.bvi-btn-group:not(:first-child)>.bvi-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

html body .bvi .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

html body .bvi .dropdown-toggle-split::after,
.dropup html body .bvi .dropdown-toggle-split::after,
.dropright html body .bvi .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft html body .bvi .dropdown-toggle-split::before {
    margin-right: 0;
}

html body .bvi .bvi-btn-sm+.dropdown-toggle-split,
html body .bvi .bvi-btn-group-sm>.bvi-btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

html body .bvi .bvi-btn-lg+.dropdown-toggle-split,
html body .bvi .bvi-btn-group-lg>.bvi-btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

html body .bvi .bvi-btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

html body .bvi .bvi-btn-group-vertical .bvi-btn,
html body .bvi .bvi-btn-group-vertical .bvi-btn-group {
    width: 100%;
}

html body .bvi .bvi-btn-group-vertical>.bvi-btn+.bvi-btn,
html body .bvi .bvi-btn-group-vertical>.bvi-btn+.bvi-btn-group,
html body .bvi .bvi-btn-group-vertical>.bvi-btn-group+.bvi-btn,
html body .bvi .bvi-btn-group-vertical>.bvi-btn-group+.bvi-btn-group {
    margin-top: -1px;
    margin-left: 0;
}

html body .bvi .bvi-btn-group-vertical>.bvi-btn:not(:last-child):not(.dropdown-toggle),
html body .bvi .bvi-btn-group-vertical>.bvi-btn-group:not(:last-child)>.bvi-btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

html body .bvi .bvi-btn-group-vertical>.bvi-btn:not(:first-child),
html body .bvi .bvi-btn-group-vertical>.bvi-btn-group:not(:first-child)>.bvi-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

html body .bvi .bvi-btn-group-toggle>.bvi-btn,
html body .bvi .bvi-btn-group-toggle>.bvi-btn-group>.bvi-btn {
    margin-bottom: 0;
}

html body .bvi .bvi-btn-group-toggle>.bvi-btn input[type="radio"],
html body .bvi .bvi-btn-group-toggle>.bvi-btn input[type="checkbox"],
html body .bvi .bvi-btn-group-toggle>.bvi-btn-group>.bvi-btn input[type="radio"],
html body .bvi .bvi-btn-group-toggle>.bvi-btn-group>.bvi-btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

html body .bvi .bvi-border {
    border: 1px solid #dee2e6 !important;
}

html body .bvi .bvi-border-top {
    border-top: 1px solid #dee2e6 !important;
}

html body .bvi .bvi-border-right {
    border-right: 1px solid #dee2e6 !important;
}

html body .bvi .bvi-border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

html body .bvi .bvi-border-left {
    border-left: 1px solid #dee2e6 !important;
}

html body .bvi .bvi-border-0 {
    border: 0 !important;
}

html body .bvi .bvi-border-top-0 {
    border-top: 0 !important;
}

html body .bvi .bvi-border-right-0 {
    border-right: 0 !important;
}

html body .bvi .bvi-border-bottom-0 {
    border-bottom: 0 !important;
}

html body .bvi .bvi-border-left-0 {
    border-left: 0 !important;
}

html body .bvi .bvi-border-primary {
    border-color: #007bff !important;
}

html body .bvi .bvi-border-secondary {
    border-color: #6c757d !important;
}

html body .bvi .bvi-border-success {
    border-color: #28a745 !important;
}

html body .bvi .bvi-border-info {
    border-color: #17a2b8 !important;
}

html body .bvi .bvi-border-warning {
    border-color: #ffc107 !important;
}

html body .bvi .bvi-border-danger {
    border-color: #dc3545 !important;
}

html body .bvi .bvi-border-light {
    border-color: #f8f9fa !important;
}

html body .bvi .bvi-border-dark {
    border-color: #343a40 !important;
}

html body .bvi .bvi-border-bvi-white {
    border-color: #ffffff !important;
}

html body .bvi .bvi-border-bvi-black {
    border-color: #000000 !important;
}

html body .bvi .bvi-border-bvi-blue {
    border-color: #063462 !important;
}

html body .bvi .bvi-border-bvi-brown {
    border-color: #4D4B43 !important;
}

html body .bvi .bvi-border-bvi-green {
    border-color: #A9E44D !important;
}

html body .bvi .bvi-border-white {
    border-color: #fff !important;
}

html body .bvi .bvi-rounded {
    border-radius: 0 !important;
}

html body .bvi .bvi-rounded-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

html body .bvi .bvi-rounded-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

html body .bvi .bvi-rounded-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

html body .bvi .bvi-rounded-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

html body .bvi .bvi-rounded-circle {
    border-radius: 50% !important;
}

html body .bvi .bvi-rounded-0 {
    border-radius: 0 !important;
}

html body .bvi .bvi-m-0 {
    margin: 0 !important;
}

html body .bvi .bvi-mt-0,
html body .bvi .bvi-my-0 {
    margin-top: 0 !important;
}

html body .bvi .bvi-mr-0,
html body .bvi .bvi-mx-0 {
    margin-right: 0 !important;
}

html body .bvi .bvi-mb-0,
html body .bvi .bvi-my-0 {
    margin-bottom: 0 !important;
}

html body .bvi .bvi-ml-0,
html body .bvi .bvi-mx-0 {
    margin-left: 0 !important;
}

html body .bvi .bvi-m-1 {
    margin: 0.25rem !important;
}

html body .bvi .bvi-mt-1,
html body .bvi .bvi-my-1 {
    margin-top: 0.25rem !important;
}

html body .bvi .bvi-mr-1,
html body .bvi .bvi-mx-1 {
    margin-right: 0.25rem !important;
}

html body .bvi .bvi-mb-1,
html body .bvi .bvi-my-1 {
    margin-bottom: 0.25rem !important;
}

html body .bvi .bvi-ml-1,
html body .bvi .bvi-mx-1 {
    margin-left: 0.25rem !important;
}

html body .bvi .bvi-m-2 {
    margin: 0.5rem !important;
}

html body .bvi .bvi-mt-2,
html body .bvi .bvi-my-2 {
    margin-top: 0.5rem !important;
}

html body .bvi .bvi-mr-2,
html body .bvi .bvi-mx-2 {
    margin-right: 0.5rem !important;
}

html body .bvi .bvi-mb-2,
html body .bvi .bvi-my-2 {
    margin-bottom: 0.5rem !important;
}

html body .bvi .bvi-ml-2,
html body .bvi .bvi-mx-2 {
    margin-left: 0.5rem !important;
}

html body .bvi .bvi-m-3 {
    margin: 1rem !important;
}

html body .bvi .bvi-mt-3,
html body .bvi .bvi-my-3 {
    margin-top: 1rem !important;
}

html body .bvi .bvi-mr-3,
html body .bvi .bvi-mx-3 {
    margin-right: 1rem !important;
}

html body .bvi .bvi-mb-3,
html body .bvi .bvi-my-3 {
    margin-bottom: 1rem !important;
}

html body .bvi .bvi-ml-3,
html body .bvi .bvi-mx-3 {
    margin-left: 1rem !important;
}

html body .bvi .bvi-m-4 {
    margin: 1.5rem !important;
}

html body .bvi .bvi-mt-4,
html body .bvi .bvi-my-4 {
    margin-top: 1.5rem !important;
}

html body .bvi .bvi-mr-4,
html body .bvi .bvi-mx-4 {
    margin-right: 1.5rem !important;
}

html body .bvi .bvi-mb-4,
html body .bvi .bvi-my-4 {
    margin-bottom: 1.5rem !important;
}

html body .bvi .bvi-ml-4,
html body .bvi .bvi-mx-4 {
    margin-left: 1.5rem !important;
}

html body .bvi .bvi-m-5 {
    margin: 3rem !important;
}

html body .bvi .bvi-mt-5,
html body .bvi .bvi-my-5 {
    margin-top: 3rem !important;
}

html body .bvi .bvi-mr-5,
html body .bvi .bvi-mx-5 {
    margin-right: 3rem !important;
}

html body .bvi .bvi-mb-5,
html body .bvi .bvi-my-5 {
    margin-bottom: 3rem !important;
}

html body .bvi .bvi-ml-5,
html body .bvi .bvi-mx-5 {
    margin-left: 3rem !important;
}

html body .bvi .bvi-p-0 {
    padding: 0 !important;
}

html body .bvi .bvi-pt-0,
html body .bvi .bvi-py-0 {
    padding-top: 0 !important;
}

html body .bvi .bvi-pr-0,
html body .bvi .bvi-px-0 {
    padding-right: 0 !important;
}

html body .bvi .bvi-pb-0,
html body .bvi .bvi-py-0 {
    padding-bottom: 0 !important;
}

html body .bvi .bvi-pl-0,
html body .bvi .bvi-px-0 {
    padding-left: 0 !important;
}

html body .bvi .bvi-p-1 {
    padding: 0.25rem !important;
}

html body .bvi .bvi-pt-1,
html body .bvi .bvi-py-1 {
    padding-top: 0.25rem !important;
}

html body .bvi .bvi-pr-1,
html body .bvi .bvi-px-1 {
    padding-right: 0.25rem !important;
}

html body .bvi .bvi-pb-1,
html body .bvi .bvi-py-1 {
    padding-bottom: 0.25rem !important;
}

html body .bvi .bvi-pl-1,
html body .bvi .bvi-px-1 {
    padding-left: 0.25rem !important;
}

html body .bvi .bvi-p-2 {
    padding: 0.5rem !important;
}

html body .bvi .bvi-pt-2,
html body .bvi .bvi-py-2 {
    padding-top: 0.5rem !important;
}

html body .bvi .bvi-pr-2,
html body .bvi .bvi-px-2 {
    padding-right: 0.5rem !important;
}

html body .bvi .bvi-pb-2,
html body .bvi .bvi-py-2 {
    padding-bottom: 0.5rem !important;
}

html body .bvi .bvi-pl-2,
html body .bvi .bvi-px-2 {
    padding-left: 0.5rem !important;
}

html body .bvi .bvi-p-3 {
    padding: 1rem !important;
}

html body .bvi .bvi-pt-3,
html body .bvi .bvi-py-3 {
    padding-top: 1rem !important;
}

html body .bvi .bvi-pr-3,
html body .bvi .bvi-px-3 {
    padding-right: 1rem !important;
}

html body .bvi .bvi-pb-3,
html body .bvi .bvi-py-3 {
    padding-bottom: 1rem !important;
}

html body .bvi .bvi-pl-3,
html body .bvi .bvi-px-3 {
    padding-left: 1rem !important;
}

html body .bvi .bvi-p-4 {
    padding: 1.5rem !important;
}

html body .bvi .bvi-pt-4,
html body .bvi .bvi-py-4 {
    padding-top: 1.5rem !important;
}

html body .bvi .bvi-pr-4,
html body .bvi .bvi-px-4 {
    padding-right: 1.5rem !important;
}

html body .bvi .bvi-pb-4,
html body .bvi .bvi-py-4 {
    padding-bottom: 1.5rem !important;
}

html body .bvi .bvi-pl-4,
html body .bvi .bvi-px-4 {
    padding-left: 1.5rem !important;
}

html body .bvi .bvi-p-5 {
    padding: 3rem !important;
}

html body .bvi .bvi-pt-5,
html body .bvi .bvi-py-5 {
    padding-top: 3rem !important;
}

html body .bvi .bvi-pr-5,
html body .bvi .bvi-px-5 {
    padding-right: 3rem !important;
}

html body .bvi .bvi-pb-5,
html body .bvi .bvi-py-5 {
    padding-bottom: 3rem !important;
}

html body .bvi .bvi-pl-5,
html body .bvi .bvi-px-5 {
    padding-left: 3rem !important;
}

html body .bvi .bvi-m-auto {
    margin: auto !important;
}

html body .bvi .bvi-mt-auto,
html body .bvi .bvi-my-auto {
    margin-top: auto !important;
}

html body .bvi .bvi-mr-auto,
html body .bvi .bvi-mx-auto {
    margin-right: auto !important;
}

html body .bvi .bvi-mb-auto,
html body .bvi .bvi-my-auto {
    margin-bottom: auto !important;
}

html body .bvi .bvi-ml-auto,
html body .bvi .bvi-mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    html body .bvi .bvi-m-sm-0 {
        margin: 0 !important;
    }

    html body .bvi .bvi-mt-sm-0,
    html body .bvi .bvi-my-sm-0 {
        margin-top: 0 !important;
    }

    html body .bvi .bvi-mr-sm-0,
    html body .bvi .bvi-mx-sm-0 {
        margin-right: 0 !important;
    }

    html body .bvi .bvi-mb-sm-0,
    html body .bvi .bvi-my-sm-0 {
        margin-bottom: 0 !important;
    }

    html body .bvi .bvi-ml-sm-0,
    html body .bvi .bvi-mx-sm-0 {
        margin-left: 0 !important;
    }

    html body .bvi .bvi-m-sm-1 {
        margin: 0.25rem !important;
    }

    html body .bvi .bvi-mt-sm-1,
    html body .bvi .bvi-my-sm-1 {
        margin-top: 0.25rem !important;
    }

    html body .bvi .bvi-mr-sm-1,
    html body .bvi .bvi-mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    html body .bvi .bvi-mb-sm-1,
    html body .bvi .bvi-my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-ml-sm-1,
    html body .bvi .bvi-mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    html body .bvi .bvi-m-sm-2 {
        margin: 0.5rem !important;
    }

    html body .bvi .bvi-mt-sm-2,
    html body .bvi .bvi-my-sm-2 {
        margin-top: 0.5rem !important;
    }

    html body .bvi .bvi-mr-sm-2,
    html body .bvi .bvi-mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    html body .bvi .bvi-mb-sm-2,
    html body .bvi .bvi-my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-ml-sm-2,
    html body .bvi .bvi-mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    html body .bvi .bvi-m-sm-3 {
        margin: 1rem !important;
    }

    html body .bvi .bvi-mt-sm-3,
    html body .bvi .bvi-my-sm-3 {
        margin-top: 1rem !important;
    }

    html body .bvi .bvi-mr-sm-3,
    html body .bvi .bvi-mx-sm-3 {
        margin-right: 1rem !important;
    }

    html body .bvi .bvi-mb-sm-3,
    html body .bvi .bvi-my-sm-3 {
        margin-bottom: 1rem !important;
    }

    html body .bvi .bvi-ml-sm-3,
    html body .bvi .bvi-mx-sm-3 {
        margin-left: 1rem !important;
    }

    html body .bvi .bvi-m-sm-4 {
        margin: 1.5rem !important;
    }

    html body .bvi .bvi-mt-sm-4,
    html body .bvi .bvi-my-sm-4 {
        margin-top: 1.5rem !important;
    }

    html body .bvi .bvi-mr-sm-4,
    html body .bvi .bvi-mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    html body .bvi .bvi-mb-sm-4,
    html body .bvi .bvi-my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-ml-sm-4,
    html body .bvi .bvi-mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    html body .bvi .bvi-m-sm-5 {
        margin: 3rem !important;
    }

    html body .bvi .bvi-mt-sm-5,
    html body .bvi .bvi-my-sm-5 {
        margin-top: 3rem !important;
    }

    html body .bvi .bvi-mr-sm-5,
    html body .bvi .bvi-mx-sm-5 {
        margin-right: 3rem !important;
    }

    html body .bvi .bvi-mb-sm-5,
    html body .bvi .bvi-my-sm-5 {
        margin-bottom: 3rem !important;
    }

    html body .bvi .bvi-ml-sm-5,
    html body .bvi .bvi-mx-sm-5 {
        margin-left: 3rem !important;
    }

    html body .bvi .bvi-p-sm-0 {
        padding: 0 !important;
    }

    html body .bvi .bvi-pt-sm-0,
    html body .bvi .bvi-py-sm-0 {
        padding-top: 0 !important;
    }

    html body .bvi .bvi-pr-sm-0,
    html body .bvi .bvi-px-sm-0 {
        padding-right: 0 !important;
    }

    html body .bvi .bvi-pb-sm-0,
    html body .bvi .bvi-py-sm-0 {
        padding-bottom: 0 !important;
    }

    html body .bvi .bvi-pl-sm-0,
    html body .bvi .bvi-px-sm-0 {
        padding-left: 0 !important;
    }

    html body .bvi .bvi-p-sm-1 {
        padding: 0.25rem !important;
    }

    html body .bvi .bvi-pt-sm-1,
    html body .bvi .bvi-py-sm-1 {
        padding-top: 0.25rem !important;
    }

    html body .bvi .bvi-pr-sm-1,
    html body .bvi .bvi-px-sm-1 {
        padding-right: 0.25rem !important;
    }

    html body .bvi .bvi-pb-sm-1,
    html body .bvi .bvi-py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-pl-sm-1,
    html body .bvi .bvi-px-sm-1 {
        padding-left: 0.25rem !important;
    }

    html body .bvi .bvi-p-sm-2 {
        padding: 0.5rem !important;
    }

    html body .bvi .bvi-pt-sm-2,
    html body .bvi .bvi-py-sm-2 {
        padding-top: 0.5rem !important;
    }

    html body .bvi .bvi-pr-sm-2,
    html body .bvi .bvi-px-sm-2 {
        padding-right: 0.5rem !important;
    }

    html body .bvi .bvi-pb-sm-2,
    html body .bvi .bvi-py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-pl-sm-2,
    html body .bvi .bvi-px-sm-2 {
        padding-left: 0.5rem !important;
    }

    html body .bvi .bvi-p-sm-3 {
        padding: 1rem !important;
    }

    html body .bvi .bvi-pt-sm-3,
    html body .bvi .bvi-py-sm-3 {
        padding-top: 1rem !important;
    }

    html body .bvi .bvi-pr-sm-3,
    html body .bvi .bvi-px-sm-3 {
        padding-right: 1rem !important;
    }

    html body .bvi .bvi-pb-sm-3,
    html body .bvi .bvi-py-sm-3 {
        padding-bottom: 1rem !important;
    }

    html body .bvi .bvi-pl-sm-3,
    html body .bvi .bvi-px-sm-3 {
        padding-left: 1rem !important;
    }

    html body .bvi .bvi-p-sm-4 {
        padding: 1.5rem !important;
    }

    html body .bvi .bvi-pt-sm-4,
    html body .bvi .bvi-py-sm-4 {
        padding-top: 1.5rem !important;
    }

    html body .bvi .bvi-pr-sm-4,
    html body .bvi .bvi-px-sm-4 {
        padding-right: 1.5rem !important;
    }

    html body .bvi .bvi-pb-sm-4,
    html body .bvi .bvi-py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-pl-sm-4,
    html body .bvi .bvi-px-sm-4 {
        padding-left: 1.5rem !important;
    }

    html body .bvi .bvi-p-sm-5 {
        padding: 3rem !important;
    }

    html body .bvi .bvi-pt-sm-5,
    html body .bvi .bvi-py-sm-5 {
        padding-top: 3rem !important;
    }

    html body .bvi .bvi-pr-sm-5,
    html body .bvi .bvi-px-sm-5 {
        padding-right: 3rem !important;
    }

    html body .bvi .bvi-pb-sm-5,
    html body .bvi .bvi-py-sm-5 {
        padding-bottom: 3rem !important;
    }

    html body .bvi .bvi-pl-sm-5,
    html body .bvi .bvi-px-sm-5 {
        padding-left: 3rem !important;
    }

    html body .bvi .bvi-m-sm-auto {
        margin: auto !important;
    }

    html body .bvi .bvi-mt-sm-auto,
    html body .bvi .bvi-my-sm-auto {
        margin-top: auto !important;
    }

    html body .bvi .bvi-mr-sm-auto,
    html body .bvi .bvi-mx-sm-auto {
        margin-right: auto !important;
    }

    html body .bvi .bvi-mb-sm-auto,
    html body .bvi .bvi-my-sm-auto {
        margin-bottom: auto !important;
    }

    html body .bvi .bvi-ml-sm-auto,
    html body .bvi .bvi-mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    html body .bvi .bvi-m-md-0 {
        margin: 0 !important;
    }

    html body .bvi .bvi-mt-md-0,
    html body .bvi .bvi-my-md-0 {
        margin-top: 0 !important;
    }

    html body .bvi .bvi-mr-md-0,
    html body .bvi .bvi-mx-md-0 {
        margin-right: 0 !important;
    }

    html body .bvi .bvi-mb-md-0,
    html body .bvi .bvi-my-md-0 {
        margin-bottom: 0 !important;
    }

    html body .bvi .bvi-ml-md-0,
    html body .bvi .bvi-mx-md-0 {
        margin-left: 0 !important;
    }

    html body .bvi .bvi-m-md-1 {
        margin: 0.25rem !important;
    }

    html body .bvi .bvi-mt-md-1,
    html body .bvi .bvi-my-md-1 {
        margin-top: 0.25rem !important;
    }

    html body .bvi .bvi-mr-md-1,
    html body .bvi .bvi-mx-md-1 {
        margin-right: 0.25rem !important;
    }

    html body .bvi .bvi-mb-md-1,
    html body .bvi .bvi-my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-ml-md-1,
    html body .bvi .bvi-mx-md-1 {
        margin-left: 0.25rem !important;
    }

    html body .bvi .bvi-m-md-2 {
        margin: 0.5rem !important;
    }

    html body .bvi .bvi-mt-md-2,
    html body .bvi .bvi-my-md-2 {
        margin-top: 0.5rem !important;
    }

    html body .bvi .bvi-mr-md-2,
    html body .bvi .bvi-mx-md-2 {
        margin-right: 0.5rem !important;
    }

    html body .bvi .bvi-mb-md-2,
    html body .bvi .bvi-my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-ml-md-2,
    html body .bvi .bvi-mx-md-2 {
        margin-left: 0.5rem !important;
    }

    html body .bvi .bvi-m-md-3 {
        margin: 1rem !important;
    }

    html body .bvi .bvi-mt-md-3,
    html body .bvi .bvi-my-md-3 {
        margin-top: 1rem !important;
    }

    html body .bvi .bvi-mr-md-3,
    html body .bvi .bvi-mx-md-3 {
        margin-right: 1rem !important;
    }

    html body .bvi .bvi-mb-md-3,
    html body .bvi .bvi-my-md-3 {
        margin-bottom: 1rem !important;
    }

    html body .bvi .bvi-ml-md-3,
    html body .bvi .bvi-mx-md-3 {
        margin-left: 1rem !important;
    }

    html body .bvi .bvi-m-md-4 {
        margin: 1.5rem !important;
    }

    html body .bvi .bvi-mt-md-4,
    html body .bvi .bvi-my-md-4 {
        margin-top: 1.5rem !important;
    }

    html body .bvi .bvi-mr-md-4,
    html body .bvi .bvi-mx-md-4 {
        margin-right: 1.5rem !important;
    }

    html body .bvi .bvi-mb-md-4,
    html body .bvi .bvi-my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-ml-md-4,
    html body .bvi .bvi-mx-md-4 {
        margin-left: 1.5rem !important;
    }

    html body .bvi .bvi-m-md-5 {
        margin: 3rem !important;
    }

    html body .bvi .bvi-mt-md-5,
    html body .bvi .bvi-my-md-5 {
        margin-top: 3rem !important;
    }

    html body .bvi .bvi-mr-md-5,
    html body .bvi .bvi-mx-md-5 {
        margin-right: 3rem !important;
    }

    html body .bvi .bvi-mb-md-5,
    html body .bvi .bvi-my-md-5 {
        margin-bottom: 3rem !important;
    }

    html body .bvi .bvi-ml-md-5,
    html body .bvi .bvi-mx-md-5 {
        margin-left: 3rem !important;
    }

    html body .bvi .bvi-p-md-0 {
        padding: 0 !important;
    }

    html body .bvi .bvi-pt-md-0,
    html body .bvi .bvi-py-md-0 {
        padding-top: 0 !important;
    }

    html body .bvi .bvi-pr-md-0,
    html body .bvi .bvi-px-md-0 {
        padding-right: 0 !important;
    }

    html body .bvi .bvi-pb-md-0,
    html body .bvi .bvi-py-md-0 {
        padding-bottom: 0 !important;
    }

    html body .bvi .bvi-pl-md-0,
    html body .bvi .bvi-px-md-0 {
        padding-left: 0 !important;
    }

    html body .bvi .bvi-p-md-1 {
        padding: 0.25rem !important;
    }

    html body .bvi .bvi-pt-md-1,
    html body .bvi .bvi-py-md-1 {
        padding-top: 0.25rem !important;
    }

    html body .bvi .bvi-pr-md-1,
    html body .bvi .bvi-px-md-1 {
        padding-right: 0.25rem !important;
    }

    html body .bvi .bvi-pb-md-1,
    html body .bvi .bvi-py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-pl-md-1,
    html body .bvi .bvi-px-md-1 {
        padding-left: 0.25rem !important;
    }

    html body .bvi .bvi-p-md-2 {
        padding: 0.5rem !important;
    }

    html body .bvi .bvi-pt-md-2,
    html body .bvi .bvi-py-md-2 {
        padding-top: 0.5rem !important;
    }

    html body .bvi .bvi-pr-md-2,
    html body .bvi .bvi-px-md-2 {
        padding-right: 0.5rem !important;
    }

    html body .bvi .bvi-pb-md-2,
    html body .bvi .bvi-py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-pl-md-2,
    html body .bvi .bvi-px-md-2 {
        padding-left: 0.5rem !important;
    }

    html body .bvi .bvi-p-md-3 {
        padding: 1rem !important;
    }

    html body .bvi .bvi-pt-md-3,
    html body .bvi .bvi-py-md-3 {
        padding-top: 1rem !important;
    }

    html body .bvi .bvi-pr-md-3,
    html body .bvi .bvi-px-md-3 {
        padding-right: 1rem !important;
    }

    html body .bvi .bvi-pb-md-3,
    html body .bvi .bvi-py-md-3 {
        padding-bottom: 1rem !important;
    }

    html body .bvi .bvi-pl-md-3,
    html body .bvi .bvi-px-md-3 {
        padding-left: 1rem !important;
    }

    html body .bvi .bvi-p-md-4 {
        padding: 1.5rem !important;
    }

    html body .bvi .bvi-pt-md-4,
    html body .bvi .bvi-py-md-4 {
        padding-top: 1.5rem !important;
    }

    html body .bvi .bvi-pr-md-4,
    html body .bvi .bvi-px-md-4 {
        padding-right: 1.5rem !important;
    }

    html body .bvi .bvi-pb-md-4,
    html body .bvi .bvi-py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-pl-md-4,
    html body .bvi .bvi-px-md-4 {
        padding-left: 1.5rem !important;
    }

    html body .bvi .bvi-p-md-5 {
        padding: 3rem !important;
    }

    html body .bvi .bvi-pt-md-5,
    html body .bvi .bvi-py-md-5 {
        padding-top: 3rem !important;
    }

    html body .bvi .bvi-pr-md-5,
    html body .bvi .bvi-px-md-5 {
        padding-right: 3rem !important;
    }

    html body .bvi .bvi-pb-md-5,
    html body .bvi .bvi-py-md-5 {
        padding-bottom: 3rem !important;
    }

    html body .bvi .bvi-pl-md-5,
    html body .bvi .bvi-px-md-5 {
        padding-left: 3rem !important;
    }

    html body .bvi .bvi-m-md-auto {
        margin: auto !important;
    }

    html body .bvi .bvi-mt-md-auto,
    html body .bvi .bvi-my-md-auto {
        margin-top: auto !important;
    }

    html body .bvi .bvi-mr-md-auto,
    html body .bvi .bvi-mx-md-auto {
        margin-right: auto !important;
    }

    html body .bvi .bvi-mb-md-auto,
    html body .bvi .bvi-my-md-auto {
        margin-bottom: auto !important;
    }

    html body .bvi .bvi-ml-md-auto,
    html body .bvi .bvi-mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    html body .bvi .bvi-m-lg-0 {
        margin: 0 !important;
    }

    html body .bvi .bvi-mt-lg-0,
    html body .bvi .bvi-my-lg-0 {
        margin-top: 0 !important;
    }

    html body .bvi .bvi-mr-lg-0,
    html body .bvi .bvi-mx-lg-0 {
        margin-right: 0 !important;
    }

    html body .bvi .bvi-mb-lg-0,
    html body .bvi .bvi-my-lg-0 {
        margin-bottom: 0 !important;
    }

    html body .bvi .bvi-ml-lg-0,
    html body .bvi .bvi-mx-lg-0 {
        margin-left: 0 !important;
    }

    html body .bvi .bvi-m-lg-1 {
        margin: 0.25rem !important;
    }

    html body .bvi .bvi-mt-lg-1,
    html body .bvi .bvi-my-lg-1 {
        margin-top: 0.25rem !important;
    }

    html body .bvi .bvi-mr-lg-1,
    html body .bvi .bvi-mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    html body .bvi .bvi-mb-lg-1,
    html body .bvi .bvi-my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-ml-lg-1,
    html body .bvi .bvi-mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    html body .bvi .bvi-m-lg-2 {
        margin: 0.5rem !important;
    }

    html body .bvi .bvi-mt-lg-2,
    html body .bvi .bvi-my-lg-2 {
        margin-top: 0.5rem !important;
    }

    html body .bvi .bvi-mr-lg-2,
    html body .bvi .bvi-mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    html body .bvi .bvi-mb-lg-2,
    html body .bvi .bvi-my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-ml-lg-2,
    html body .bvi .bvi-mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    html body .bvi .bvi-m-lg-3 {
        margin: 1rem !important;
    }

    html body .bvi .bvi-mt-lg-3,
    html body .bvi .bvi-my-lg-3 {
        margin-top: 1rem !important;
    }

    html body .bvi .bvi-mr-lg-3,
    html body .bvi .bvi-mx-lg-3 {
        margin-right: 1rem !important;
    }

    html body .bvi .bvi-mb-lg-3,
    html body .bvi .bvi-my-lg-3 {
        margin-bottom: 1rem !important;
    }

    html body .bvi .bvi-ml-lg-3,
    html body .bvi .bvi-mx-lg-3 {
        margin-left: 1rem !important;
    }

    html body .bvi .bvi-m-lg-4 {
        margin: 1.5rem !important;
    }

    html body .bvi .bvi-mt-lg-4,
    html body .bvi .bvi-my-lg-4 {
        margin-top: 1.5rem !important;
    }

    html body .bvi .bvi-mr-lg-4,
    html body .bvi .bvi-mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    html body .bvi .bvi-mb-lg-4,
    html body .bvi .bvi-my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-ml-lg-4,
    html body .bvi .bvi-mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    html body .bvi .bvi-m-lg-5 {
        margin: 3rem !important;
    }

    html body .bvi .bvi-mt-lg-5,
    html body .bvi .bvi-my-lg-5 {
        margin-top: 3rem !important;
    }

    html body .bvi .bvi-mr-lg-5,
    html body .bvi .bvi-mx-lg-5 {
        margin-right: 3rem !important;
    }

    html body .bvi .bvi-mb-lg-5,
    html body .bvi .bvi-my-lg-5 {
        margin-bottom: 3rem !important;
    }

    html body .bvi .bvi-ml-lg-5,
    html body .bvi .bvi-mx-lg-5 {
        margin-left: 3rem !important;
    }

    html body .bvi .bvi-p-lg-0 {
        padding: 0 !important;
    }

    html body .bvi .bvi-pt-lg-0,
    html body .bvi .bvi-py-lg-0 {
        padding-top: 0 !important;
    }

    html body .bvi .bvi-pr-lg-0,
    html body .bvi .bvi-px-lg-0 {
        padding-right: 0 !important;
    }

    html body .bvi .bvi-pb-lg-0,
    html body .bvi .bvi-py-lg-0 {
        padding-bottom: 0 !important;
    }

    html body .bvi .bvi-pl-lg-0,
    html body .bvi .bvi-px-lg-0 {
        padding-left: 0 !important;
    }

    html body .bvi .bvi-p-lg-1 {
        padding: 0.25rem !important;
    }

    html body .bvi .bvi-pt-lg-1,
    html body .bvi .bvi-py-lg-1 {
        padding-top: 0.25rem !important;
    }

    html body .bvi .bvi-pr-lg-1,
    html body .bvi .bvi-px-lg-1 {
        padding-right: 0.25rem !important;
    }

    html body .bvi .bvi-pb-lg-1,
    html body .bvi .bvi-py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-pl-lg-1,
    html body .bvi .bvi-px-lg-1 {
        padding-left: 0.25rem !important;
    }

    html body .bvi .bvi-p-lg-2 {
        padding: 0.5rem !important;
    }

    html body .bvi .bvi-pt-lg-2,
    html body .bvi .bvi-py-lg-2 {
        padding-top: 0.5rem !important;
    }

    html body .bvi .bvi-pr-lg-2,
    html body .bvi .bvi-px-lg-2 {
        padding-right: 0.5rem !important;
    }

    html body .bvi .bvi-pb-lg-2,
    html body .bvi .bvi-py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-pl-lg-2,
    html body .bvi .bvi-px-lg-2 {
        padding-left: 0.5rem !important;
    }

    html body .bvi .bvi-p-lg-3 {
        padding: 1rem !important;
    }

    html body .bvi .bvi-pt-lg-3,
    html body .bvi .bvi-py-lg-3 {
        padding-top: 1rem !important;
    }

    html body .bvi .bvi-pr-lg-3,
    html body .bvi .bvi-px-lg-3 {
        padding-right: 1rem !important;
    }

    html body .bvi .bvi-pb-lg-3,
    html body .bvi .bvi-py-lg-3 {
        padding-bottom: 1rem !important;
    }

    html body .bvi .bvi-pl-lg-3,
    html body .bvi .bvi-px-lg-3 {
        padding-left: 1rem !important;
    }

    html body .bvi .bvi-p-lg-4 {
        padding: 1.5rem !important;
    }

    html body .bvi .bvi-pt-lg-4,
    html body .bvi .bvi-py-lg-4 {
        padding-top: 1.5rem !important;
    }

    html body .bvi .bvi-pr-lg-4,
    html body .bvi .bvi-px-lg-4 {
        padding-right: 1.5rem !important;
    }

    html body .bvi .bvi-pb-lg-4,
    html body .bvi .bvi-py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-pl-lg-4,
    html body .bvi .bvi-px-lg-4 {
        padding-left: 1.5rem !important;
    }

    html body .bvi .bvi-p-lg-5 {
        padding: 3rem !important;
    }

    html body .bvi .bvi-pt-lg-5,
    html body .bvi .bvi-py-lg-5 {
        padding-top: 3rem !important;
    }

    html body .bvi .bvi-pr-lg-5,
    html body .bvi .bvi-px-lg-5 {
        padding-right: 3rem !important;
    }

    html body .bvi .bvi-pb-lg-5,
    html body .bvi .bvi-py-lg-5 {
        padding-bottom: 3rem !important;
    }

    html body .bvi .bvi-pl-lg-5,
    html body .bvi .bvi-px-lg-5 {
        padding-left: 3rem !important;
    }

    html body .bvi .bvi-m-lg-auto {
        margin: auto !important;
    }

    html body .bvi .bvi-mt-lg-auto,
    html body .bvi .bvi-my-lg-auto {
        margin-top: auto !important;
    }

    html body .bvi .bvi-mr-lg-auto,
    html body .bvi .bvi-mx-lg-auto {
        margin-right: auto !important;
    }

    html body .bvi .bvi-mb-lg-auto,
    html body .bvi .bvi-my-lg-auto {
        margin-bottom: auto !important;
    }

    html body .bvi .bvi-ml-lg-auto,
    html body .bvi .bvi-mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    html body .bvi .bvi-m-xl-0 {
        margin: 0 !important;
    }

    html body .bvi .bvi-mt-xl-0,
    html body .bvi .bvi-my-xl-0 {
        margin-top: 0 !important;
    }

    html body .bvi .bvi-mr-xl-0,
    html body .bvi .bvi-mx-xl-0 {
        margin-right: 0 !important;
    }

    html body .bvi .bvi-mb-xl-0,
    html body .bvi .bvi-my-xl-0 {
        margin-bottom: 0 !important;
    }

    html body .bvi .bvi-ml-xl-0,
    html body .bvi .bvi-mx-xl-0 {
        margin-left: 0 !important;
    }

    html body .bvi .bvi-m-xl-1 {
        margin: 0.25rem !important;
    }

    html body .bvi .bvi-mt-xl-1,
    html body .bvi .bvi-my-xl-1 {
        margin-top: 0.25rem !important;
    }

    html body .bvi .bvi-mr-xl-1,
    html body .bvi .bvi-mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    html body .bvi .bvi-mb-xl-1,
    html body .bvi .bvi-my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-ml-xl-1,
    html body .bvi .bvi-mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    html body .bvi .bvi-m-xl-2 {
        margin: 0.5rem !important;
    }

    html body .bvi .bvi-mt-xl-2,
    html body .bvi .bvi-my-xl-2 {
        margin-top: 0.5rem !important;
    }

    html body .bvi .bvi-mr-xl-2,
    html body .bvi .bvi-mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    html body .bvi .bvi-mb-xl-2,
    html body .bvi .bvi-my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-ml-xl-2,
    html body .bvi .bvi-mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    html body .bvi .bvi-m-xl-3 {
        margin: 1rem !important;
    }

    html body .bvi .bvi-mt-xl-3,
    html body .bvi .bvi-my-xl-3 {
        margin-top: 1rem !important;
    }

    html body .bvi .bvi-mr-xl-3,
    html body .bvi .bvi-mx-xl-3 {
        margin-right: 1rem !important;
    }

    html body .bvi .bvi-mb-xl-3,
    html body .bvi .bvi-my-xl-3 {
        margin-bottom: 1rem !important;
    }

    html body .bvi .bvi-ml-xl-3,
    html body .bvi .bvi-mx-xl-3 {
        margin-left: 1rem !important;
    }

    html body .bvi .bvi-m-xl-4 {
        margin: 1.5rem !important;
    }

    html body .bvi .bvi-mt-xl-4,
    html body .bvi .bvi-my-xl-4 {
        margin-top: 1.5rem !important;
    }

    html body .bvi .bvi-mr-xl-4,
    html body .bvi .bvi-mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    html body .bvi .bvi-mb-xl-4,
    html body .bvi .bvi-my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-ml-xl-4,
    html body .bvi .bvi-mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    html body .bvi .bvi-m-xl-5 {
        margin: 3rem !important;
    }

    html body .bvi .bvi-mt-xl-5,
    html body .bvi .bvi-my-xl-5 {
        margin-top: 3rem !important;
    }

    html body .bvi .bvi-mr-xl-5,
    html body .bvi .bvi-mx-xl-5 {
        margin-right: 3rem !important;
    }

    html body .bvi .bvi-mb-xl-5,
    html body .bvi .bvi-my-xl-5 {
        margin-bottom: 3rem !important;
    }

    html body .bvi .bvi-ml-xl-5,
    html body .bvi .bvi-mx-xl-5 {
        margin-left: 3rem !important;
    }

    html body .bvi .bvi-p-xl-0 {
        padding: 0 !important;
    }

    html body .bvi .bvi-pt-xl-0,
    html body .bvi .bvi-py-xl-0 {
        padding-top: 0 !important;
    }

    html body .bvi .bvi-pr-xl-0,
    html body .bvi .bvi-px-xl-0 {
        padding-right: 0 !important;
    }

    html body .bvi .bvi-pb-xl-0,
    html body .bvi .bvi-py-xl-0 {
        padding-bottom: 0 !important;
    }

    html body .bvi .bvi-pl-xl-0,
    html body .bvi .bvi-px-xl-0 {
        padding-left: 0 !important;
    }

    html body .bvi .bvi-p-xl-1 {
        padding: 0.25rem !important;
    }

    html body .bvi .bvi-pt-xl-1,
    html body .bvi .bvi-py-xl-1 {
        padding-top: 0.25rem !important;
    }

    html body .bvi .bvi-pr-xl-1,
    html body .bvi .bvi-px-xl-1 {
        padding-right: 0.25rem !important;
    }

    html body .bvi .bvi-pb-xl-1,
    html body .bvi .bvi-py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    html body .bvi .bvi-pl-xl-1,
    html body .bvi .bvi-px-xl-1 {
        padding-left: 0.25rem !important;
    }

    html body .bvi .bvi-p-xl-2 {
        padding: 0.5rem !important;
    }

    html body .bvi .bvi-pt-xl-2,
    html body .bvi .bvi-py-xl-2 {
        padding-top: 0.5rem !important;
    }

    html body .bvi .bvi-pr-xl-2,
    html body .bvi .bvi-px-xl-2 {
        padding-right: 0.5rem !important;
    }

    html body .bvi .bvi-pb-xl-2,
    html body .bvi .bvi-py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    html body .bvi .bvi-pl-xl-2,
    html body .bvi .bvi-px-xl-2 {
        padding-left: 0.5rem !important;
    }

    html body .bvi .bvi-p-xl-3 {
        padding: 1rem !important;
    }

    html body .bvi .bvi-pt-xl-3,
    html body .bvi .bvi-py-xl-3 {
        padding-top: 1rem !important;
    }

    html body .bvi .bvi-pr-xl-3,
    html body .bvi .bvi-px-xl-3 {
        padding-right: 1rem !important;
    }

    html body .bvi .bvi-pb-xl-3,
    html body .bvi .bvi-py-xl-3 {
        padding-bottom: 1rem !important;
    }

    html body .bvi .bvi-pl-xl-3,
    html body .bvi .bvi-px-xl-3 {
        padding-left: 1rem !important;
    }

    html body .bvi .bvi-p-xl-4 {
        padding: 1.5rem !important;
    }

    html body .bvi .bvi-pt-xl-4,
    html body .bvi .bvi-py-xl-4 {
        padding-top: 1.5rem !important;
    }

    html body .bvi .bvi-pr-xl-4,
    html body .bvi .bvi-px-xl-4 {
        padding-right: 1.5rem !important;
    }

    html body .bvi .bvi-pb-xl-4,
    html body .bvi .bvi-py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    html body .bvi .bvi-pl-xl-4,
    html body .bvi .bvi-px-xl-4 {
        padding-left: 1.5rem !important;
    }

    html body .bvi .bvi-p-xl-5 {
        padding: 3rem !important;
    }

    html body .bvi .bvi-pt-xl-5,
    html body .bvi .bvi-py-xl-5 {
        padding-top: 3rem !important;
    }

    html body .bvi .bvi-pr-xl-5,
    html body .bvi .bvi-px-xl-5 {
        padding-right: 3rem !important;
    }

    html body .bvi .bvi-pb-xl-5,
    html body .bvi .bvi-py-xl-5 {
        padding-bottom: 3rem !important;
    }

    html body .bvi .bvi-pl-xl-5,
    html body .bvi .bvi-px-xl-5 {
        padding-left: 3rem !important;
    }

    html body .bvi .bvi-m-xl-auto {
        margin: auto !important;
    }

    html body .bvi .bvi-mt-xl-auto,
    html body .bvi .bvi-my-xl-auto {
        margin-top: auto !important;
    }

    html body .bvi .bvi-mr-xl-auto,
    html body .bvi .bvi-mx-xl-auto {
        margin-right: auto !important;
    }

    html body .bvi .bvi-mb-xl-auto,
    html body .bvi .bvi-my-xl-auto {
        margin-bottom: auto !important;
    }

    html body .bvi .bvi-ml-xl-auto,
    html body .bvi .bvi-mx-xl-auto {
        margin-left: auto !important;
    }
}

html body .bvi.bvi-panel {
    padding: 15px 20px !important;
    color: black !important;
    background-color: #f8f8f8;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    letter-spacing: 0 !important;
    position: relative !important;
    text-shadow: none !important;
    line-height: normal !important;
    border: 0 !important;
    font-size: 16px !important;
    z-index: 99999 !important;
    moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
}

html body .bvi.bvi-panel[data-bvi-panel='true'] {
    display: block !important;
}

html body .bvi.bvi-panel[data-bvi-panel='false'] {
    display: none !important;
}

html body .bvi.bvi-panel.bvi-fixed-top {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 999999 !important;
}

html body .bvi.bvi-panel .bvi-title {
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
}

html body .bvi.bvi-panel .bvi-text-center {
    text-align: center;
}

html body .bvi.bvi-panel .bvi-text-right {
    text-align: right;
}

html body .bvi.bvi-panel .bvi-setting-menu {
    display: none;
}

html body .bvi.bvi-panel a {
    text-decoration: none;
}

html body .bvi.bvi-panel .bvi-hr {
    background-color: #ccc !important;
    border: 0 !important;
    height: 1px !important;
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
}

html body .bvi.bvi-panel .bvi-copy {
    font-size: 12px;
    color: #808080;
}

html body .bvi.bvi-panel .bvi-copy a {
    font-size: 13px;
    color: #808080;
    text-decoration: underline;
}

html body .bvi.bvi-panel .bvi-copy a:hover {
    color: #000000;
    text-decoration: underline;
}

@media (max-width: 575.98px) {
    html body .bvi.bvi-panel .bvi-title {
        font-size: 12px;
    }

    html body .bvi.bvi-panel .bvi-btn {
        font-size: 12px;
        padding: 0.200rem 0.35rem;
    }

    html body .bvi.bvi-panel .bvi-btn-sm,
    html body .bvi.bvi-panel .bvi-btn-group-sm>.bvi-btn {
        font-size: 10px;
        padding: 0.100rem 0.25rem;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    html body .bvi.bvi-panel .bvi-title {
        font-size: 14px;
    }

    html body .bvi.bvi-panel .bvi-btn {
        font-size: 14px;
        padding: 0.250rem 0.45rem;
    }

    html body .bvi.bvi-panel .bvi-btn-sm,
    html body .bvi.bvi-panel .bvi-btn-group-sm>.bvi-btn {
        font-size: 12px;
        padding: 0.150rem 0.35rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    html body .bvi.bvi-panel .bvi-title {
        font-size: 16px;
    }

    html body .bvi.bvi-panel .bvi-btn {
        font-size: 16px;
        padding: 0.300rem 0.55rem;
    }

    html body .bvi.bvi-panel .bvi-btn-sm,
    html body .bvi.bvi-panel .bvi-btn-group-sm>.bvi-btn {
        font-size: 14px;
        padding: 0.200rem 0.40rem;
    }
}

html body .bvi-body {
    /*
      &[data-bvi-images='true'] {
        .bvi-images-on {
          display: block;
        }
        img {
          display: block;
        }
      }
      */
    /*
      &[data-bvi-images='false'] {
        img.bvi-images-off,
        .bvi-images-off {
          border: 2px dashed !important;
          box-sizing: border-box !important;
          overflow: hidden !important;
          max-width: 100% !important;
          max-height: 100% !important;
          //word-break: break-all !important;
          word-wrap: break-word !important;
          text-align: center !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }
      &[data-bvi-images='grayscale'] {
        img.bvi-images-grayscale {
          filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
          -webkit-filter: grayscale(1);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: grayscale(1);
          filter: gray;
          -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)";
        }
      }
      */
}

html body .bvi-body[data-bvi-theme='white'] {
    background-color: #ffffff !important;
    fill: #000000 !important;
}

html body .bvi-body[data-bvi-theme='white'] * {
    background-color: #ffffff !important;
    background: #ffffff !important;
    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #000000 !important;
    color: #000000 !important;
    fill: #000000 !important;
}

html body .bvi-body[data-bvi-theme='white'] * .bvi-hide {
    display: none !important;
}

html body .bvi-body[data-bvi-theme='white'] * .bvi-show-block {
    display: block !important;
}

html body .bvi-body[data-bvi-theme='white'] * .bvi-show-inline-block {
    display: inline-block !important;
}

html body .bvi-body[data-bvi-theme='white'] *:after {
    background-color: #ffffff !important;
    background: #ffffff !important;
    background-image: none !important;
    color: #000000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #000000 !important;
}

html body .bvi-body[data-bvi-theme='white'] *:before {
    background-color: #ffffff !important;
    background: #ffffff !important;
    background-image: none !important;
    color: #000000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #000000 !important;
}

html body .bvi-body[data-bvi-theme='white'] *::selection {
    background-color: #000000;
    color: #ffffff;
}

html body .bvi-body[data-bvi-theme='white'] * a,
html body .bvi-body[data-bvi-theme='white'] * label {
    color: #000000 !important;
    text-decoration: underline !important;
    /*
      &:active {
        color: none !important;
      }
      &:link {
        color: none !important;
      }
      &:visited {
        color: none !important;
      }
      */
}

html body .bvi-body[data-bvi-theme='white'] * a.active,
html body .bvi-body[data-bvi-theme='white'] * label.active {
    color: #ffffff !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * a:hover,
html body .bvi-body[data-bvi-theme='white'] * label:hover {
    color: #ffffff !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * a:hover *,
html body .bvi-body[data-bvi-theme='white'] * label:hover * {
    color: #ffffff !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * button,
html body .bvi-body[data-bvi-theme='white'] * input[type="submit"],
html body .bvi-body[data-bvi-theme='white'] * input[type="button"],
html body .bvi-body[data-bvi-theme='white'] * button[type="submit"],
html body .bvi-body[data-bvi-theme='white'] * button[type="button"] {
    color: #ffffff !important;
    background: #000000 !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * button:hover.active,
html body .bvi-body[data-bvi-theme='white'] * input[type="button"]:hover.active,
html body .bvi-body[data-bvi-theme='white'] * input[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='white'] * button[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='white'] * button[type="button"]:hover.active {
    color: #ffffff !important;
    background: #000000 !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * button:hover:hover,
html body .bvi-body[data-bvi-theme='white'] * input[type="button"]:hover:hover,
html body .bvi-body[data-bvi-theme='white'] * input[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='white'] * button[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='white'] * button[type="button"]:hover:hover {
    color: #ffffff !important;
    background: #000000 !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='white'] * button:hover:hover *,
html body .bvi-body[data-bvi-theme='white'] * input[type="button"]:hover:hover *,
html body .bvi-body[data-bvi-theme='white'] * input[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='white'] * button[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='white'] * button[type="button"]:hover:hover * {
    color: #ffffff !important;
    background: #000000 !important;
    background-color: #000000 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] {
    background-color: #000000 !important;
    fill: #ffffff !important;
}

html body .bvi-body[data-bvi-theme='black'] * {
    background-color: #000000 !important;
    background: #000000 !important;
    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #ffffff !important;
    color: #ffffff !important;
    fill: #ffffff !important;
}

html body .bvi-body[data-bvi-theme='black'] * .bvi-hide {
    display: none !important;
}

html body .bvi-body[data-bvi-theme='black'] * .bvi-show-block {
    display: block !important;
}

html body .bvi-body[data-bvi-theme='black'] * .bvi-show-inline-block {
    display: inline-block !important;
}

html body .bvi-body[data-bvi-theme='black'] *:after {
    background-color: #000000 !important;
    background: #000000 !important;
    background-image: none !important;
    color: #ffffff !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #ffffff !important;
}

html body .bvi-body[data-bvi-theme='black'] *:before {
    background-color: #000000 !important;
    background: #000000 !important;
    background-image: none !important;
    color: #ffffff !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #ffffff !important;
}

html body .bvi-body[data-bvi-theme='black'] *::selection {
    background-color: #ffffff;
    color: #000000;
}

html body .bvi-body[data-bvi-theme='black'] * a,
html body .bvi-body[data-bvi-theme='black'] * label {
    color: #ffffff !important;
    text-decoration: underline !important;
    /*
      &:active {
        color: none !important;
      }
      &:link {
        color: none !important;
      }
      &:visited {
        color: none !important;
      }
      */
}

html body .bvi-body[data-bvi-theme='black'] * a.active,
html body .bvi-body[data-bvi-theme='black'] * label.active {
    color: #000000 !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * a:hover,
html body .bvi-body[data-bvi-theme='black'] * label:hover {
    color: #000000 !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * a:hover *,
html body .bvi-body[data-bvi-theme='black'] * label:hover * {
    color: #000000 !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * button,
html body .bvi-body[data-bvi-theme='black'] * input[type="submit"],
html body .bvi-body[data-bvi-theme='black'] * input[type="button"],
html body .bvi-body[data-bvi-theme='black'] * button[type="submit"],
html body .bvi-body[data-bvi-theme='black'] * button[type="button"] {
    color: #000000 !important;
    background: #ffffff !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * button:hover.active,
html body .bvi-body[data-bvi-theme='black'] * input[type="button"]:hover.active,
html body .bvi-body[data-bvi-theme='black'] * input[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='black'] * button[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='black'] * button[type="button"]:hover.active {
    color: #000000 !important;
    background: #ffffff !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * button:hover:hover,
html body .bvi-body[data-bvi-theme='black'] * input[type="button"]:hover:hover,
html body .bvi-body[data-bvi-theme='black'] * input[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='black'] * button[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='black'] * button[type="button"]:hover:hover {
    color: #000000 !important;
    background: #ffffff !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='black'] * button:hover:hover *,
html body .bvi-body[data-bvi-theme='black'] * input[type="button"]:hover:hover *,
html body .bvi-body[data-bvi-theme='black'] * input[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='black'] * button[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='black'] * button[type="button"]:hover:hover * {
    color: #000000 !important;
    background: #ffffff !important;
    background-color: #ffffff !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] {
    background-color: #9DD1FF !important;
    fill: #063462 !important;
}

html body .bvi-body[data-bvi-theme='blue'] * {
    background-color: #9DD1FF !important;
    background: #9DD1FF !important;
    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #063462 !important;
    color: #063462 !important;
    fill: #063462 !important;
}

html body .bvi-body[data-bvi-theme='blue'] * .bvi-hide {
    display: none !important;
}

html body .bvi-body[data-bvi-theme='blue'] * .bvi-show-block {
    display: block !important;
}

html body .bvi-body[data-bvi-theme='blue'] * .bvi-show-inline-block {
    display: inline-block !important;
}

html body .bvi-body[data-bvi-theme='blue'] *:after {
    background-color: #9DD1FF !important;
    background: #9DD1FF !important;
    background-image: none !important;
    color: #063462 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #063462 !important;
}

html body .bvi-body[data-bvi-theme='blue'] *:before {
    background-color: #9DD1FF !important;
    background: #9DD1FF !important;
    background-image: none !important;
    color: #063462 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #063462 !important;
}

html body .bvi-body[data-bvi-theme='blue'] *::selection {
    background-color: #063462;
    color: #9DD1FF;
}

html body .bvi-body[data-bvi-theme='blue'] * a,
html body .bvi-body[data-bvi-theme='blue'] * label {
    color: #063462 !important;
    text-decoration: underline !important;
    /*
      &:active {
        color: none !important;
      }
      &:link {
        color: none !important;
      }
      &:visited {
        color: none !important;
      }
      */
}

html body .bvi-body[data-bvi-theme='blue'] * a.active,
html body .bvi-body[data-bvi-theme='blue'] * label.active {
    color: #9DD1FF !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * a:hover,
html body .bvi-body[data-bvi-theme='blue'] * label:hover {
    color: #9DD1FF !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * a:hover *,
html body .bvi-body[data-bvi-theme='blue'] * label:hover * {
    color: #9DD1FF !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * button,
html body .bvi-body[data-bvi-theme='blue'] * input[type="submit"],
html body .bvi-body[data-bvi-theme='blue'] * input[type="button"],
html body .bvi-body[data-bvi-theme='blue'] * button[type="submit"],
html body .bvi-body[data-bvi-theme='blue'] * button[type="button"] {
    color: #9DD1FF !important;
    background: #063462 !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * button:hover.active,
html body .bvi-body[data-bvi-theme='blue'] * input[type="button"]:hover.active,
html body .bvi-body[data-bvi-theme='blue'] * input[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='blue'] * button[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='blue'] * button[type="button"]:hover.active {
    color: #9DD1FF !important;
    background: #063462 !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * button:hover:hover,
html body .bvi-body[data-bvi-theme='blue'] * input[type="button"]:hover:hover,
html body .bvi-body[data-bvi-theme='blue'] * input[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='blue'] * button[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='blue'] * button[type="button"]:hover:hover {
    color: #9DD1FF !important;
    background: #063462 !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='blue'] * button:hover:hover *,
html body .bvi-body[data-bvi-theme='blue'] * input[type="button"]:hover:hover *,
html body .bvi-body[data-bvi-theme='blue'] * input[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='blue'] * button[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='blue'] * button[type="button"]:hover:hover * {
    color: #9DD1FF !important;
    background: #063462 !important;
    background-color: #063462 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] {
    background-color: #F7F3D6 !important;
    fill: #4D4B43 !important;
}

html body .bvi-body[data-bvi-theme='brown'] * {
    background-color: #F7F3D6 !important;
    background: #F7F3D6 !important;
    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #4D4B43 !important;
    color: #4D4B43 !important;
    fill: #4D4B43 !important;
}

html body .bvi-body[data-bvi-theme='brown'] * .bvi-hide {
    display: none !important;
}

html body .bvi-body[data-bvi-theme='brown'] * .bvi-show-block {
    display: block !important;
}

html body .bvi-body[data-bvi-theme='brown'] * .bvi-show-inline-block {
    display: inline-block !important;
}

html body .bvi-body[data-bvi-theme='brown'] *:after {
    background-color: #F7F3D6 !important;
    background: #F7F3D6 !important;
    background-image: none !important;
    color: #4D4B43 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #4D4B43 !important;
}

html body .bvi-body[data-bvi-theme='brown'] *:before {
    background-color: #F7F3D6 !important;
    background: #F7F3D6 !important;
    background-image: none !important;
    color: #4D4B43 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #4D4B43 !important;
}

html body .bvi-body[data-bvi-theme='brown'] *::selection {
    background-color: #4D4B43;
    color: #F7F3D6;
}

html body .bvi-body[data-bvi-theme='brown'] * a,
html body .bvi-body[data-bvi-theme='brown'] * label {
    color: #4D4B43 !important;
    text-decoration: underline !important;
    /*
      &:active {
        color: none !important;
      }
      &:link {
        color: none !important;
      }
      &:visited {
        color: none !important;
      }
      */
}

html body .bvi-body[data-bvi-theme='brown'] * a.active,
html body .bvi-body[data-bvi-theme='brown'] * label.active {
    color: #F7F3D6 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * a:hover,
html body .bvi-body[data-bvi-theme='brown'] * label:hover {
    color: #F7F3D6 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * a:hover *,
html body .bvi-body[data-bvi-theme='brown'] * label:hover * {
    color: #F7F3D6 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * button,
html body .bvi-body[data-bvi-theme='brown'] * input[type="submit"],
html body .bvi-body[data-bvi-theme='brown'] * input[type="button"],
html body .bvi-body[data-bvi-theme='brown'] * button[type="submit"],
html body .bvi-body[data-bvi-theme='brown'] * button[type="button"] {
    color: #F7F3D6 !important;
    background: #4D4B43 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * button:hover.active,
html body .bvi-body[data-bvi-theme='brown'] * input[type="button"]:hover.active,
html body .bvi-body[data-bvi-theme='brown'] * input[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='brown'] * button[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='brown'] * button[type="button"]:hover.active {
    color: #F7F3D6 !important;
    background: #4D4B43 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * button:hover:hover,
html body .bvi-body[data-bvi-theme='brown'] * input[type="button"]:hover:hover,
html body .bvi-body[data-bvi-theme='brown'] * input[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='brown'] * button[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='brown'] * button[type="button"]:hover:hover {
    color: #F7F3D6 !important;
    background: #4D4B43 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='brown'] * button:hover:hover *,
html body .bvi-body[data-bvi-theme='brown'] * input[type="button"]:hover:hover *,
html body .bvi-body[data-bvi-theme='brown'] * input[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='brown'] * button[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='brown'] * button[type="button"]:hover:hover * {
    color: #F7F3D6 !important;
    background: #4D4B43 !important;
    background-color: #4D4B43 !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] {
    background-color: #3B2716 !important;
    fill: #A9E44D !important;
}

html body .bvi-body[data-bvi-theme='green'] * {
    background-color: #3B2716 !important;
    background: #3B2716 !important;
    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #A9E44D !important;
    color: #A9E44D !important;
    fill: #A9E44D !important;
}

html body .bvi-body[data-bvi-theme='green'] * .bvi-hide {
    display: none !important;
}

html body .bvi-body[data-bvi-theme='green'] * .bvi-show-block {
    display: block !important;
}

html body .bvi-body[data-bvi-theme='green'] * .bvi-show-inline-block {
    display: inline-block !important;
}

html body .bvi-body[data-bvi-theme='green'] *:after {
    background-color: #3B2716 !important;
    background: #3B2716 !important;
    background-image: none !important;
    color: #A9E44D !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #A9E44D !important;
}

html body .bvi-body[data-bvi-theme='green'] *:before {
    background-color: #3B2716 !important;
    background: #3B2716 !important;
    background-image: none !important;
    color: #A9E44D !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-color: #A9E44D !important;
}

html body .bvi-body[data-bvi-theme='green'] *::selection {
    background-color: #A9E44D;
    color: #3B2716;
}

html body .bvi-body[data-bvi-theme='green'] * a,
html body .bvi-body[data-bvi-theme='green'] * label {
    color: #A9E44D !important;
    text-decoration: underline !important;
    /*
      &:active {
        color: none !important;
      }
      &:link {
        color: none !important;
      }
      &:visited {
        color: none !important;
      }
      */
}

html body .bvi-body[data-bvi-theme='green'] * a.active,
html body .bvi-body[data-bvi-theme='green'] * label.active {
    color: #3B2716 !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * a:hover,
html body .bvi-body[data-bvi-theme='green'] * label:hover {
    color: #3B2716 !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * a:hover *,
html body .bvi-body[data-bvi-theme='green'] * label:hover * {
    color: #3B2716 !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * button,
html body .bvi-body[data-bvi-theme='green'] * input[type="submit"],
html body .bvi-body[data-bvi-theme='green'] * input[type="button"],
html body .bvi-body[data-bvi-theme='green'] * button[type="submit"],
html body .bvi-body[data-bvi-theme='green'] * button[type="button"] {
    color: #3B2716 !important;
    background: #A9E44D !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * button:hover.active,
html body .bvi-body[data-bvi-theme='green'] * input[type="button"]:hover.active,
html body .bvi-body[data-bvi-theme='green'] * input[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='green'] * button[type="submit"]:hover.active,
html body .bvi-body[data-bvi-theme='green'] * button[type="button"]:hover.active {
    color: #3B2716 !important;
    background: #A9E44D !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * button:hover:hover,
html body .bvi-body[data-bvi-theme='green'] * input[type="button"]:hover:hover,
html body .bvi-body[data-bvi-theme='green'] * input[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='green'] * button[type="submit"]:hover:hover,
html body .bvi-body[data-bvi-theme='green'] * button[type="button"]:hover:hover {
    color: #3B2716 !important;
    background: #A9E44D !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-theme='green'] * button:hover:hover *,
html body .bvi-body[data-bvi-theme='green'] * input[type="button"]:hover:hover *,
html body .bvi-body[data-bvi-theme='green'] * input[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='green'] * button[type="submit"]:hover:hover *,
html body .bvi-body[data-bvi-theme='green'] * button[type="button"]:hover:hover * {
    color: #3B2716 !important;
    background: #A9E44D !important;
    background-color: #A9E44D !important;
    text-decoration: underline !important;
}

html body .bvi-body[data-bvi-size='40'] * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='40'] * h1,
html body .bvi-body[data-bvi-size='40'] * h1 * {
    font-size: 47px !important;
}

html body .bvi-body[data-bvi-size='40'] * h2,
html body .bvi-body[data-bvi-size='40'] * h2 * {
    font-size: 46px !important;
}

html body .bvi-body[data-bvi-size='40'] * h3,
html body .bvi-body[data-bvi-size='40'] * h3 * {
    font-size: 45px !important;
}

html body .bvi-body[data-bvi-size='40'] * h4,
html body .bvi-body[data-bvi-size='40'] * h4 * {
    font-size: 44px !important;
}

html body .bvi-body[data-bvi-size='40'] * h5,
html body .bvi-body[data-bvi-size='40'] * h5 * {
    font-size: 43px !important;
}

html body .bvi-body[data-bvi-size='40'] * h6,
html body .bvi-body[data-bvi-size='40'] * h6 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='39'] * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='39'] * h1,
html body .bvi-body[data-bvi-size='39'] * h1 * {
    font-size: 46px !important;
}

html body .bvi-body[data-bvi-size='39'] * h2,
html body .bvi-body[data-bvi-size='39'] * h2 * {
    font-size: 45px !important;
}

html body .bvi-body[data-bvi-size='39'] * h3,
html body .bvi-body[data-bvi-size='39'] * h3 * {
    font-size: 44px !important;
}

html body .bvi-body[data-bvi-size='39'] * h4,
html body .bvi-body[data-bvi-size='39'] * h4 * {
    font-size: 43px !important;
}

html body .bvi-body[data-bvi-size='39'] * h5,
html body .bvi-body[data-bvi-size='39'] * h5 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='39'] * h6,
html body .bvi-body[data-bvi-size='39'] * h6 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='38'] * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='38'] * h1,
html body .bvi-body[data-bvi-size='38'] * h1 * {
    font-size: 45px !important;
}

html body .bvi-body[data-bvi-size='38'] * h2,
html body .bvi-body[data-bvi-size='38'] * h2 * {
    font-size: 44px !important;
}

html body .bvi-body[data-bvi-size='38'] * h3,
html body .bvi-body[data-bvi-size='38'] * h3 * {
    font-size: 43px !important;
}

html body .bvi-body[data-bvi-size='38'] * h4,
html body .bvi-body[data-bvi-size='38'] * h4 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='38'] * h5,
html body .bvi-body[data-bvi-size='38'] * h5 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='38'] * h6,
html body .bvi-body[data-bvi-size='38'] * h6 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='37'] * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='37'] * h1,
html body .bvi-body[data-bvi-size='37'] * h1 * {
    font-size: 44px !important;
}

html body .bvi-body[data-bvi-size='37'] * h2,
html body .bvi-body[data-bvi-size='37'] * h2 * {
    font-size: 43px !important;
}

html body .bvi-body[data-bvi-size='37'] * h3,
html body .bvi-body[data-bvi-size='37'] * h3 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='37'] * h4,
html body .bvi-body[data-bvi-size='37'] * h4 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='37'] * h5,
html body .bvi-body[data-bvi-size='37'] * h5 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='37'] * h6,
html body .bvi-body[data-bvi-size='37'] * h6 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='36'] * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='36'] * h1,
html body .bvi-body[data-bvi-size='36'] * h1 * {
    font-size: 43px !important;
}

html body .bvi-body[data-bvi-size='36'] * h2,
html body .bvi-body[data-bvi-size='36'] * h2 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='36'] * h3,
html body .bvi-body[data-bvi-size='36'] * h3 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='36'] * h4,
html body .bvi-body[data-bvi-size='36'] * h4 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='36'] * h5,
html body .bvi-body[data-bvi-size='36'] * h5 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='36'] * h6,
html body .bvi-body[data-bvi-size='36'] * h6 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='35'] * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='35'] * h1,
html body .bvi-body[data-bvi-size='35'] * h1 * {
    font-size: 42px !important;
}

html body .bvi-body[data-bvi-size='35'] * h2,
html body .bvi-body[data-bvi-size='35'] * h2 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='35'] * h3,
html body .bvi-body[data-bvi-size='35'] * h3 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='35'] * h4,
html body .bvi-body[data-bvi-size='35'] * h4 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='35'] * h5,
html body .bvi-body[data-bvi-size='35'] * h5 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='35'] * h6,
html body .bvi-body[data-bvi-size='35'] * h6 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='34'] * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='34'] * h1,
html body .bvi-body[data-bvi-size='34'] * h1 * {
    font-size: 41px !important;
}

html body .bvi-body[data-bvi-size='34'] * h2,
html body .bvi-body[data-bvi-size='34'] * h2 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='34'] * h3,
html body .bvi-body[data-bvi-size='34'] * h3 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='34'] * h4,
html body .bvi-body[data-bvi-size='34'] * h4 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='34'] * h5,
html body .bvi-body[data-bvi-size='34'] * h5 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='34'] * h6,
html body .bvi-body[data-bvi-size='34'] * h6 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='33'] * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='33'] * h1,
html body .bvi-body[data-bvi-size='33'] * h1 * {
    font-size: 40px !important;
}

html body .bvi-body[data-bvi-size='33'] * h2,
html body .bvi-body[data-bvi-size='33'] * h2 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='33'] * h3,
html body .bvi-body[data-bvi-size='33'] * h3 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='33'] * h4,
html body .bvi-body[data-bvi-size='33'] * h4 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='33'] * h5,
html body .bvi-body[data-bvi-size='33'] * h5 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='33'] * h6,
html body .bvi-body[data-bvi-size='33'] * h6 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='32'] * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='32'] * h1,
html body .bvi-body[data-bvi-size='32'] * h1 * {
    font-size: 39px !important;
}

html body .bvi-body[data-bvi-size='32'] * h2,
html body .bvi-body[data-bvi-size='32'] * h2 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='32'] * h3,
html body .bvi-body[data-bvi-size='32'] * h3 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='32'] * h4,
html body .bvi-body[data-bvi-size='32'] * h4 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='32'] * h5,
html body .bvi-body[data-bvi-size='32'] * h5 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='32'] * h6,
html body .bvi-body[data-bvi-size='32'] * h6 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='31'] * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='31'] * h1,
html body .bvi-body[data-bvi-size='31'] * h1 * {
    font-size: 38px !important;
}

html body .bvi-body[data-bvi-size='31'] * h2,
html body .bvi-body[data-bvi-size='31'] * h2 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='31'] * h3,
html body .bvi-body[data-bvi-size='31'] * h3 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='31'] * h4,
html body .bvi-body[data-bvi-size='31'] * h4 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='31'] * h5,
html body .bvi-body[data-bvi-size='31'] * h5 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='31'] * h6,
html body .bvi-body[data-bvi-size='31'] * h6 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='30'] * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='30'] * h1,
html body .bvi-body[data-bvi-size='30'] * h1 * {
    font-size: 37px !important;
}

html body .bvi-body[data-bvi-size='30'] * h2,
html body .bvi-body[data-bvi-size='30'] * h2 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='30'] * h3,
html body .bvi-body[data-bvi-size='30'] * h3 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='30'] * h4,
html body .bvi-body[data-bvi-size='30'] * h4 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='30'] * h5,
html body .bvi-body[data-bvi-size='30'] * h5 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='30'] * h6,
html body .bvi-body[data-bvi-size='30'] * h6 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='29'] * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='29'] * h1,
html body .bvi-body[data-bvi-size='29'] * h1 * {
    font-size: 36px !important;
}

html body .bvi-body[data-bvi-size='29'] * h2,
html body .bvi-body[data-bvi-size='29'] * h2 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='29'] * h3,
html body .bvi-body[data-bvi-size='29'] * h3 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='29'] * h4,
html body .bvi-body[data-bvi-size='29'] * h4 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='29'] * h5,
html body .bvi-body[data-bvi-size='29'] * h5 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='29'] * h6,
html body .bvi-body[data-bvi-size='29'] * h6 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='28'] * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='28'] * h1,
html body .bvi-body[data-bvi-size='28'] * h1 * {
    font-size: 35px !important;
}

html body .bvi-body[data-bvi-size='28'] * h2,
html body .bvi-body[data-bvi-size='28'] * h2 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='28'] * h3,
html body .bvi-body[data-bvi-size='28'] * h3 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='28'] * h4,
html body .bvi-body[data-bvi-size='28'] * h4 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='28'] * h5,
html body .bvi-body[data-bvi-size='28'] * h5 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='28'] * h6,
html body .bvi-body[data-bvi-size='28'] * h6 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='27'] * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='27'] * h1,
html body .bvi-body[data-bvi-size='27'] * h1 * {
    font-size: 34px !important;
}

html body .bvi-body[data-bvi-size='27'] * h2,
html body .bvi-body[data-bvi-size='27'] * h2 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='27'] * h3,
html body .bvi-body[data-bvi-size='27'] * h3 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='27'] * h4,
html body .bvi-body[data-bvi-size='27'] * h4 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='27'] * h5,
html body .bvi-body[data-bvi-size='27'] * h5 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='27'] * h6,
html body .bvi-body[data-bvi-size='27'] * h6 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='26'] * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='26'] * h1,
html body .bvi-body[data-bvi-size='26'] * h1 * {
    font-size: 33px !important;
}

html body .bvi-body[data-bvi-size='26'] * h2,
html body .bvi-body[data-bvi-size='26'] * h2 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='26'] * h3,
html body .bvi-body[data-bvi-size='26'] * h3 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='26'] * h4,
html body .bvi-body[data-bvi-size='26'] * h4 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='26'] * h5,
html body .bvi-body[data-bvi-size='26'] * h5 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='26'] * h6,
html body .bvi-body[data-bvi-size='26'] * h6 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='25'] * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='25'] * h1,
html body .bvi-body[data-bvi-size='25'] * h1 * {
    font-size: 32px !important;
}

html body .bvi-body[data-bvi-size='25'] * h2,
html body .bvi-body[data-bvi-size='25'] * h2 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='25'] * h3,
html body .bvi-body[data-bvi-size='25'] * h3 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='25'] * h4,
html body .bvi-body[data-bvi-size='25'] * h4 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='25'] * h5,
html body .bvi-body[data-bvi-size='25'] * h5 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='25'] * h6,
html body .bvi-body[data-bvi-size='25'] * h6 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='24'] * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='24'] * h1,
html body .bvi-body[data-bvi-size='24'] * h1 * {
    font-size: 31px !important;
}

html body .bvi-body[data-bvi-size='24'] * h2,
html body .bvi-body[data-bvi-size='24'] * h2 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='24'] * h3,
html body .bvi-body[data-bvi-size='24'] * h3 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='24'] * h4,
html body .bvi-body[data-bvi-size='24'] * h4 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='24'] * h5,
html body .bvi-body[data-bvi-size='24'] * h5 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='24'] * h6,
html body .bvi-body[data-bvi-size='24'] * h6 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='23'] * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='23'] * h1,
html body .bvi-body[data-bvi-size='23'] * h1 * {
    font-size: 30px !important;
}

html body .bvi-body[data-bvi-size='23'] * h2,
html body .bvi-body[data-bvi-size='23'] * h2 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='23'] * h3,
html body .bvi-body[data-bvi-size='23'] * h3 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='23'] * h4,
html body .bvi-body[data-bvi-size='23'] * h4 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='23'] * h5,
html body .bvi-body[data-bvi-size='23'] * h5 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='23'] * h6,
html body .bvi-body[data-bvi-size='23'] * h6 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='22'] * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='22'] * h1,
html body .bvi-body[data-bvi-size='22'] * h1 * {
    font-size: 29px !important;
}

html body .bvi-body[data-bvi-size='22'] * h2,
html body .bvi-body[data-bvi-size='22'] * h2 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='22'] * h3,
html body .bvi-body[data-bvi-size='22'] * h3 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='22'] * h4,
html body .bvi-body[data-bvi-size='22'] * h4 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='22'] * h5,
html body .bvi-body[data-bvi-size='22'] * h5 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='22'] * h6,
html body .bvi-body[data-bvi-size='22'] * h6 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='21'] * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='21'] * h1,
html body .bvi-body[data-bvi-size='21'] * h1 * {
    font-size: 28px !important;
}

html body .bvi-body[data-bvi-size='21'] * h2,
html body .bvi-body[data-bvi-size='21'] * h2 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='21'] * h3,
html body .bvi-body[data-bvi-size='21'] * h3 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='21'] * h4,
html body .bvi-body[data-bvi-size='21'] * h4 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='21'] * h5,
html body .bvi-body[data-bvi-size='21'] * h5 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='21'] * h6,
html body .bvi-body[data-bvi-size='21'] * h6 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='20'] * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='20'] * h1,
html body .bvi-body[data-bvi-size='20'] * h1 * {
    font-size: 27px !important;
}

html body .bvi-body[data-bvi-size='20'] * h2,
html body .bvi-body[data-bvi-size='20'] * h2 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='20'] * h3,
html body .bvi-body[data-bvi-size='20'] * h3 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='20'] * h4,
html body .bvi-body[data-bvi-size='20'] * h4 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='20'] * h5,
html body .bvi-body[data-bvi-size='20'] * h5 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='20'] * h6,
html body .bvi-body[data-bvi-size='20'] * h6 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='19'] * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='19'] * h1,
html body .bvi-body[data-bvi-size='19'] * h1 * {
    font-size: 26px !important;
}

html body .bvi-body[data-bvi-size='19'] * h2,
html body .bvi-body[data-bvi-size='19'] * h2 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='19'] * h3,
html body .bvi-body[data-bvi-size='19'] * h3 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='19'] * h4,
html body .bvi-body[data-bvi-size='19'] * h4 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='19'] * h5,
html body .bvi-body[data-bvi-size='19'] * h5 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='19'] * h6,
html body .bvi-body[data-bvi-size='19'] * h6 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='18'] * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='18'] * h1,
html body .bvi-body[data-bvi-size='18'] * h1 * {
    font-size: 25px !important;
}

html body .bvi-body[data-bvi-size='18'] * h2,
html body .bvi-body[data-bvi-size='18'] * h2 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='18'] * h3,
html body .bvi-body[data-bvi-size='18'] * h3 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='18'] * h4,
html body .bvi-body[data-bvi-size='18'] * h4 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='18'] * h5,
html body .bvi-body[data-bvi-size='18'] * h5 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='18'] * h6,
html body .bvi-body[data-bvi-size='18'] * h6 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='17'] * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='17'] * h1,
html body .bvi-body[data-bvi-size='17'] * h1 * {
    font-size: 24px !important;
}

html body .bvi-body[data-bvi-size='17'] * h2,
html body .bvi-body[data-bvi-size='17'] * h2 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='17'] * h3,
html body .bvi-body[data-bvi-size='17'] * h3 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='17'] * h4,
html body .bvi-body[data-bvi-size='17'] * h4 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='17'] * h5,
html body .bvi-body[data-bvi-size='17'] * h5 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='17'] * h6,
html body .bvi-body[data-bvi-size='17'] * h6 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='16'] * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='16'] * h1,
html body .bvi-body[data-bvi-size='16'] * h1 * {
    font-size: 23px !important;
}

html body .bvi-body[data-bvi-size='16'] * h2,
html body .bvi-body[data-bvi-size='16'] * h2 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='16'] * h3,
html body .bvi-body[data-bvi-size='16'] * h3 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='16'] * h4,
html body .bvi-body[data-bvi-size='16'] * h4 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='16'] * h5,
html body .bvi-body[data-bvi-size='16'] * h5 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='16'] * h6,
html body .bvi-body[data-bvi-size='16'] * h6 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='15'] * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='15'] * h1,
html body .bvi-body[data-bvi-size='15'] * h1 * {
    font-size: 22px !important;
}

html body .bvi-body[data-bvi-size='15'] * h2,
html body .bvi-body[data-bvi-size='15'] * h2 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='15'] * h3,
html body .bvi-body[data-bvi-size='15'] * h3 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='15'] * h4,
html body .bvi-body[data-bvi-size='15'] * h4 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='15'] * h5,
html body .bvi-body[data-bvi-size='15'] * h5 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='15'] * h6,
html body .bvi-body[data-bvi-size='15'] * h6 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='14'] * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='14'] * h1,
html body .bvi-body[data-bvi-size='14'] * h1 * {
    font-size: 21px !important;
}

html body .bvi-body[data-bvi-size='14'] * h2,
html body .bvi-body[data-bvi-size='14'] * h2 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='14'] * h3,
html body .bvi-body[data-bvi-size='14'] * h3 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='14'] * h4,
html body .bvi-body[data-bvi-size='14'] * h4 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='14'] * h5,
html body .bvi-body[data-bvi-size='14'] * h5 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='14'] * h6,
html body .bvi-body[data-bvi-size='14'] * h6 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='13'] * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='13'] * h1,
html body .bvi-body[data-bvi-size='13'] * h1 * {
    font-size: 20px !important;
}

html body .bvi-body[data-bvi-size='13'] * h2,
html body .bvi-body[data-bvi-size='13'] * h2 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='13'] * h3,
html body .bvi-body[data-bvi-size='13'] * h3 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='13'] * h4,
html body .bvi-body[data-bvi-size='13'] * h4 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='13'] * h5,
html body .bvi-body[data-bvi-size='13'] * h5 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='13'] * h6,
html body .bvi-body[data-bvi-size='13'] * h6 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='12'] * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='12'] * h1,
html body .bvi-body[data-bvi-size='12'] * h1 * {
    font-size: 19px !important;
}

html body .bvi-body[data-bvi-size='12'] * h2,
html body .bvi-body[data-bvi-size='12'] * h2 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='12'] * h3,
html body .bvi-body[data-bvi-size='12'] * h3 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='12'] * h4,
html body .bvi-body[data-bvi-size='12'] * h4 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='12'] * h5,
html body .bvi-body[data-bvi-size='12'] * h5 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='12'] * h6,
html body .bvi-body[data-bvi-size='12'] * h6 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='11'] * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='11'] * h1,
html body .bvi-body[data-bvi-size='11'] * h1 * {
    font-size: 18px !important;
}

html body .bvi-body[data-bvi-size='11'] * h2,
html body .bvi-body[data-bvi-size='11'] * h2 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='11'] * h3,
html body .bvi-body[data-bvi-size='11'] * h3 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='11'] * h4,
html body .bvi-body[data-bvi-size='11'] * h4 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='11'] * h5,
html body .bvi-body[data-bvi-size='11'] * h5 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='11'] * h6,
html body .bvi-body[data-bvi-size='11'] * h6 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='10'] * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='10'] * h1,
html body .bvi-body[data-bvi-size='10'] * h1 * {
    font-size: 17px !important;
}

html body .bvi-body[data-bvi-size='10'] * h2,
html body .bvi-body[data-bvi-size='10'] * h2 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='10'] * h3,
html body .bvi-body[data-bvi-size='10'] * h3 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='10'] * h4,
html body .bvi-body[data-bvi-size='10'] * h4 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='10'] * h5,
html body .bvi-body[data-bvi-size='10'] * h5 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='10'] * h6,
html body .bvi-body[data-bvi-size='10'] * h6 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='9'] * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='9'] * h1,
html body .bvi-body[data-bvi-size='9'] * h1 * {
    font-size: 16px !important;
}

html body .bvi-body[data-bvi-size='9'] * h2,
html body .bvi-body[data-bvi-size='9'] * h2 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='9'] * h3,
html body .bvi-body[data-bvi-size='9'] * h3 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='9'] * h4,
html body .bvi-body[data-bvi-size='9'] * h4 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='9'] * h5,
html body .bvi-body[data-bvi-size='9'] * h5 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='9'] * h6,
html body .bvi-body[data-bvi-size='9'] * h6 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='8'] * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='8'] * h1,
html body .bvi-body[data-bvi-size='8'] * h1 * {
    font-size: 15px !important;
}

html body .bvi-body[data-bvi-size='8'] * h2,
html body .bvi-body[data-bvi-size='8'] * h2 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='8'] * h3,
html body .bvi-body[data-bvi-size='8'] * h3 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='8'] * h4,
html body .bvi-body[data-bvi-size='8'] * h4 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='8'] * h5,
html body .bvi-body[data-bvi-size='8'] * h5 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='8'] * h6,
html body .bvi-body[data-bvi-size='8'] * h6 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='7'] * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='7'] * h1,
html body .bvi-body[data-bvi-size='7'] * h1 * {
    font-size: 14px !important;
}

html body .bvi-body[data-bvi-size='7'] * h2,
html body .bvi-body[data-bvi-size='7'] * h2 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='7'] * h3,
html body .bvi-body[data-bvi-size='7'] * h3 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='7'] * h4,
html body .bvi-body[data-bvi-size='7'] * h4 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='7'] * h5,
html body .bvi-body[data-bvi-size='7'] * h5 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='7'] * h6,
html body .bvi-body[data-bvi-size='7'] * h6 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='6'] * {
    font-size: 6px !important;
}

html body .bvi-body[data-bvi-size='6'] * h1,
html body .bvi-body[data-bvi-size='6'] * h1 * {
    font-size: 13px !important;
}

html body .bvi-body[data-bvi-size='6'] * h2,
html body .bvi-body[data-bvi-size='6'] * h2 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='6'] * h3,
html body .bvi-body[data-bvi-size='6'] * h3 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='6'] * h4,
html body .bvi-body[data-bvi-size='6'] * h4 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='6'] * h5,
html body .bvi-body[data-bvi-size='6'] * h5 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='6'] * h6,
html body .bvi-body[data-bvi-size='6'] * h6 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='5'] * {
    font-size: 5px !important;
}

html body .bvi-body[data-bvi-size='5'] * h1,
html body .bvi-body[data-bvi-size='5'] * h1 * {
    font-size: 12px !important;
}

html body .bvi-body[data-bvi-size='5'] * h2,
html body .bvi-body[data-bvi-size='5'] * h2 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='5'] * h3,
html body .bvi-body[data-bvi-size='5'] * h3 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='5'] * h4,
html body .bvi-body[data-bvi-size='5'] * h4 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='5'] * h5,
html body .bvi-body[data-bvi-size='5'] * h5 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='5'] * h6,
html body .bvi-body[data-bvi-size='5'] * h6 * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='4'] * {
    font-size: 4px !important;
}

html body .bvi-body[data-bvi-size='4'] * h1,
html body .bvi-body[data-bvi-size='4'] * h1 * {
    font-size: 11px !important;
}

html body .bvi-body[data-bvi-size='4'] * h2,
html body .bvi-body[data-bvi-size='4'] * h2 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='4'] * h3,
html body .bvi-body[data-bvi-size='4'] * h3 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='4'] * h4,
html body .bvi-body[data-bvi-size='4'] * h4 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='4'] * h5,
html body .bvi-body[data-bvi-size='4'] * h5 * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='4'] * h6,
html body .bvi-body[data-bvi-size='4'] * h6 * {
    font-size: 6px !important;
}

html body .bvi-body[data-bvi-size='3'] * {
    font-size: 3px !important;
}

html body .bvi-body[data-bvi-size='3'] * h1,
html body .bvi-body[data-bvi-size='3'] * h1 * {
    font-size: 10px !important;
}

html body .bvi-body[data-bvi-size='3'] * h2,
html body .bvi-body[data-bvi-size='3'] * h2 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='3'] * h3,
html body .bvi-body[data-bvi-size='3'] * h3 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='3'] * h4,
html body .bvi-body[data-bvi-size='3'] * h4 * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='3'] * h5,
html body .bvi-body[data-bvi-size='3'] * h5 * {
    font-size: 6px !important;
}

html body .bvi-body[data-bvi-size='3'] * h6,
html body .bvi-body[data-bvi-size='3'] * h6 * {
    font-size: 5px !important;
}

html body .bvi-body[data-bvi-size='2'] * {
    font-size: 2px !important;
}

html body .bvi-body[data-bvi-size='2'] * h1,
html body .bvi-body[data-bvi-size='2'] * h1 * {
    font-size: 9px !important;
}

html body .bvi-body[data-bvi-size='2'] * h2,
html body .bvi-body[data-bvi-size='2'] * h2 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='2'] * h3,
html body .bvi-body[data-bvi-size='2'] * h3 * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='2'] * h4,
html body .bvi-body[data-bvi-size='2'] * h4 * {
    font-size: 6px !important;
}

html body .bvi-body[data-bvi-size='2'] * h5,
html body .bvi-body[data-bvi-size='2'] * h5 * {
    font-size: 5px !important;
}

html body .bvi-body[data-bvi-size='2'] * h6,
html body .bvi-body[data-bvi-size='2'] * h6 * {
    font-size: 4px !important;
}

html body .bvi-body[data-bvi-size='1'] * {
    font-size: 1px !important;
}

html body .bvi-body[data-bvi-size='1'] * h1,
html body .bvi-body[data-bvi-size='1'] * h1 * {
    font-size: 8px !important;
}

html body .bvi-body[data-bvi-size='1'] * h2,
html body .bvi-body[data-bvi-size='1'] * h2 * {
    font-size: 7px !important;
}

html body .bvi-body[data-bvi-size='1'] * h3,
html body .bvi-body[data-bvi-size='1'] * h3 * {
    font-size: 6px !important;
}

html body .bvi-body[data-bvi-size='1'] * h4,
html body .bvi-body[data-bvi-size='1'] * h4 * {
    font-size: 5px !important;
}

html body .bvi-body[data-bvi-size='1'] * h5,
html body .bvi-body[data-bvi-size='1'] * h5 * {
    font-size: 4px !important;
}

html body .bvi-body[data-bvi-size='1'] * h6,
html body .bvi-body[data-bvi-size='1'] * h6 * {
    font-size: 3px !important;
}

html body .bvi-body[data-bvi-images='true'] img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

html body .bvi-body[data-bvi-images='false'] .bvi-img-off {
    border: 2px dashed !important;
    box-sizing: border-box !important;
    overflow: hidden !important;
    max-width: 100% !important;
    max-height: 100% !important;
    word-wrap: break-word !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

html body .bvi-body[data-bvi-images='grayscale'] img.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    filter: gray;
}

html body .bvi-body[data-bvi-images='grayscale'] .bvi-div-grayscale {
    position: relative;
    display: block;
}

html body .bvi-body[data-bvi-line-height='normal'] * {
    line-height: 1.5 !important;
}

html body .bvi-body[data-bvi-line-height='average'] * {
    line-height: 2 !important;
}

html body .bvi-body[data-bvi-line-height='big'] * {
    line-height: 2.5 !important;
}

html body .bvi-body[data-bvi-letter-spacing='normal'] * {
    letter-spacing: 0px !important;
}

html body .bvi-body[data-bvi-letter-spacing='average'] * {
    letter-spacing: 2px !important;
}

html body .bvi-body[data-bvi-letter-spacing='big'] * {
    letter-spacing: 4px !important;
}

html body .bvi-body[data-bvi-font-family='arial'] *:not(i):not(span) {
    font-family: Arial, sans-serif;
}

html body .bvi-body[data-bvi-font-family='times'] *:not(i):not(span) {
    font-family: "Times New roman";
}

html body .bvi-body[data-bvi-flash-iframe='true'] iframe,
html body .bvi-body[data-bvi-flash-iframe='true'] video,
html body .bvi-body[data-bvi-flash-iframe='true'] figure {
    display: block;
}

html body .bvi-body[data-bvi-flash-iframe='false'] iframe,
html body .bvi-body[data-bvi-flash-iframe='false'] video,
html body .bvi-body[data-bvi-flash-iframe='false'] figure {
    display: none;
}