//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0,
    sm: 330px,
    md: 768px,
    lg: 1024px,
    xl: 1120px,
    xxl: 1600px) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.


$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 980px,
    xl: 1120px,
    xxl: 1260px) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// END of Grid Options

// Bootstrap file imports
@import "../../../node_modules/bootstrap-4-grid/scss/breakpoints/mixins/breakpoints";
@import "../../../node_modules/bootstrap-4-grid/scss/grid/bootstrap-grid";
@import "../../../node_modules/bootstrap-4-grid/scss/sizing/utilities/sizing";
@import "../../../node_modules/bootstrap-4-grid/scss/layout/utilities/position";
// @import "../../../node_modules/bootstrap-4-grid/scss/layout/utilities/spacing";
@import "../../../node_modules/bootstrap-4-grid/scss/layout/mixins/visibility";
@import "../../../node_modules/bootstrap-4-grid/scss/layout/utilities/visibility";