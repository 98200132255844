@import '../../../node_modules/sass-rem/_rem';
@import '../../../node_modules/sass-em/_em';

@import '_fancybox';
@import "_slick";

@import '../../../node_modules/air-datepicker/dist/css/datepicker.min';
.datepicker {
  z-index: 99999;
  border: none; }
