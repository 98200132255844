.notice {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-150px);
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    padding: 20px;
    z-index: 99999;
    transition: all 0.3s linear;
    opacity: 0;

    &--open {
        transform: none;
        opacity: 1;
    }

    &--green {
        background: $green;
        box-shadow: 0 5px 10px rgba($green, 0.2);
    }

    &--red {
        background: $red;
        box-shadow: 0 5px 10px rgba($red, 0.2);
    }
}