// Colors
$color-1: #8a248f;
$color-2: #d97c65;
// Fonts
$main-font: 'Kelson Sans RU';
$fz: 15px;

$notebook: 1600px;
$notebook-sm: 1440px;
$notebook-xs: 1280px;
$tablet: 1160px;
$tablet-sm: 1023px;
$mobile: 767px;
$mobile-xs: 320px;
