$header-height: 126px;
$mb: 30px;
$margin: 40px;

@keyframes errorInput {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-6px);
  }

  100% {
    transform: translateX(6px);
  }
}

.contacts-module {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  & > * {
    margin-left: 30px;
    display: flex;
    align-items: center;
  }

  img {
    width: 12px;
    display: block;
    margin-right: 5px;
  }

  a {
    color: #ffffff80 !important;
  }
}

.color-blue {
  color: $color-1;
}

.weight {
  font-weight: 500;
}

.menu-button {
  width: 30px;
  height: 23px;
  display: block;
  position: relative;
  margin: 0px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    background: #fff;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 10px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 20px;
      transform-origin: left center;
    }
  }

  &.open span {
    &:nth-child(1) {
      transform: rotate(45deg);
      top: 0px;
      left: 8px;
    }

    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
      top: 21px;
      left: 8px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
  overflow: hidden;

  & > * {
    width: 100%;
  }
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-width: 150px;

      &:first-child {
        margin-bottom: 15px;
      }

      &:last-child {
        width: 40px;
      }
    }
  }
}

.section {
  margin: $margin 0;
}

.input-date {
  position: relative;
  height: 45px !important;

  &:after {
    content: "";
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    background: url("../img/icons/calendar.svg") center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}

.field {
  text-align: left;
  $parent: &;
  margin-bottom: 20px;
  position: relative;

  &--row {
    display: flex;
    align-items: center;

    .messages {
      left: 150px !important;
    }

    label {
      width: 150px;
      flex-shrink: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      .messages {
        left: 0 !important;
      }

      label {
        margin-bottom: 5px !important;
        display: block;
        width: 100%;
      }
    }
  }

  &__group {
    width: 100%;
    display: flex;
  }

  .avatar-module {
    margin-right: 30px;
    overflow: hidden;
    border-radius: 5px;

    img {
      max-width: 100%;
      display: block;
    }
  }

  &--not-label {
    padding-left: 150px;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }

  label {
    font-size: 16px;
    margin: 0 0 10px 0;
    display: block;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  input,
  textarea,
  select {
    border: 1px solid #edf1ff;
    width: 100%;
    background: #edf1ff;
    font-size: 18px;
    height: 45px;
    font-family: $main-font;
    border-radius: 0px;
    padding-left: 10px;

    &:hover {
      border-color: rgb(214, 218, 234);
    }
  }

  textarea {
    resize: vertical;
    min-height: 115px;
    padding-top: 5px;
    text-align: left !important;
    overflow: hidden !important;
  }

  &--stroke {
    input,
    select {
      height: 35px;
    }

    input,
    textarea,
    select {
      border: none;
      border-bottom: 2px solid #c3c8db;
      border-radius: 0;
      background: transparent;
      padding-left: 0;
      transition: all 0.2s ease;

      &:hover {
        border-color: $color-1;
      }
    }

    label {
      color: #9097db;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1;
    }
  }

  &--white {
    label {
      margin-bottom: 5px;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      font-family: $main-font;
      letter-spacing: normal;
      display: block;
    }

    input,
    select {
      border: solid 1px transparent;
      background: rgba(#fff, 0.2);
      border-radius: 5px;
      height: 45px;
      color: #fff;
      padding-left: 15px;

      &:hover {
        border-color: transparent;
      }
    }
  }

  &--center {
    text-align: center;
  }

  &--error {
    label {
      color: red;
    }

    input,
    textarea,
    select {
      border-color: red !important;
      animation: errorInput 0.2s ease;
    }

    &#{$parent}--white {
      label {
        color: #fff;
      }

      input,
      textarea,
      select {
        background: rgba(255, 0, 0, 0.4);
        border-color: transparent;
      }
    }
  }

  .messages {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 11px;
    margin-top: 5px;
    color: red;
  }
}

.attach {
  display: inline-block;
  vertical-align: top;

  input {
    display: none;
  }
}

.checkbox,
.radio {
  user-select: none;
  font-size: 14px !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-family: $main-font !important;
  font-weight: 400 !important;
  cursor: pointer;
  display: block;
  $parent: &;

  a {
    color: #eeac2d;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__content {
    display: block;
    position: relative;
    padding-left: 35px;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border: solid 1px #fff;
      background: #fff;
      border-radius: 2px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      position: absolute;
      top: -2px;
      left: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 12px;
      background: url("../img/icons/check-black.svg") center no-repeat !important;
      background-size: contain;
      display: none;
      position: absolute;
      top: 2px;
      left: 3px;
    }
  }

  input {
    display: none;

    &:checked + span {
      &:after {
        display: block;
      }
    }
  }

  &--error {
    .checkbox__content,
    .radio__content {
      color: #fff;

      &:before {
        background: rgba(255, 0, 0, 0.4);
        border-color: rgba(255, 0, 0, 0.4) !important;
      }
    }

    &:hover {
      .checkbox__icon,
      .radio__icon {
        border-color: red;
      }
    }
  }

  &--disabled {
    color: #bdbdbd;
    cursor: not-allowed;

    &:hover {
      .checkbox__icon,
      .radio__icon {
        border-color: #ccc;
      }
    }
  }
}

.gender {
  display: flex;
  height: 45px;
  align-items: center;
  $parent: &;

  &__val {
    display: flex;
    height: inherit;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      width: 9px;
      height: 9px;
      background: #fff;
      background: #fff;
      border-radius: 18px;
      position: absolute;
      top: 13px;
      left: 7px;
      opacity: 0;
      transition: all 0.2s ease;
    }
  }

  svg {
    fill: rgba(#fff, 0.2);
    width: 30px;
    transition: all 0.2s ease;
    height: 30px;
    margin-right: 5px;
  }

  &__item {
    cursor: pointer;
    height: inherit;
    margin-right: 10px !important;
    margin-bottom: 0 !important;
    font-size: 16px !important;

    &:first-child {
      svg {
        margin-top: -18px;
      }
    }

    &:last-child {
      margin-right: 0 !important;

      svg {
        width: 22px;
        margin-top: -2px;
      }
    }

    &:hover {
      svg {
        fill: rgba(#fff, 0.8);
      }
    }
  }

  input {
    display: none;

    &:checked + span.gender__val {
      svg {
        fill: #fff;
      }

      &:before {
        opacity: 1;
      }
    }
  }
}

.header {
  position: relative;
  color: #000;
  background-size: 100%;
  z-index: 10;
  background: #fff;
  $parent: &;

  .contacts-module {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .menu-button {
    display: none;
  }

  &--bg {
    background: url("../img/intro/bg.jpg") center top no-repeat;
    background-size: cover;
    color: #fff;

    a {
      color: #fff;
    }

    #{$parent}__logo {
      color: #fff;
    }

    #{$parent}__nav {
      a {
        color: #fff;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(#00082f, 0.3);
  }

  .container {
    position: relative;
    z-index: 2;
  }

  a {
    cursor: pointer;
  }

  &__icons {
    position: absolute;
    left: 100%;
    margin-left: 50px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;

      svg {
        width: 26px;
        height: 20px;
        fill: #fff;
      }

      &:hover,
      &.active {
        svg {
          fill: #000;
        }
      }
    }

    @media (max-width: 1600px) {
      margin-left: 30px;
    }

    @media (max-width: 1560px) {
      margin-left: 0;
    }

    @media (max-width: 1320px) {
      top: 0px;
      left: inherit;
      right: 5px;
      transform: none;

      a {
        width: 25px;
        height: 32px;

        svg {
          width: 20px;
          height: 15px;
        }
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;

    img {
      display: block;
      width: 100px;
      margin-right: 25px;
      flex-shrink: 0;
    }

    h2 {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 700;
      margin-right: 10px;
      span {
        display: block;
        margin-top: 5px;
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    @include media-breakpoint-down(xl) {
      h2 {
        font-size: 13px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-grow: initial;

      img {
        margin-right: 0;
      }
    }
  }

  &__nav {
    ul {
      display: flex;
      white-space: nowrap;
    }

    li {
      margin: 0 20px;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        a {
          color: #7c6ad2;
        }
      }
    }

    a {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.02em;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .authorized {
    margin-left: auto;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-right: -7.5px;

    & > * {
      margin: 7.5px;
    }
  }

  &__search {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    transform: translateY(-5px);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);

    form {
      display: flex;
      align-items: center;
    }

    &.open {
      transform: none;
      visibility: visible;
      opacity: 1;
    }

    input {
      flex-grow: 1;
      height: 50px;
      font-size: 18px;
      font-family: $main-font;
      padding-left: 20px;
    }

    button {
      font-size: 18px;
      background: rgb(4, 10, 111);
      color: rgb(255, 255, 255);
      height: 50px;
      padding: 0 25px;
      flex-shrink: 0;

      &:hover {
        background: $color-1;
      }
    }

    @include media-breakpoint-down(sm) {
      left: 5px;
      right: 5px;
      width: auto;

      input {
        font-size: 14px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &__nav,
    .authorized {
      display: none;
    }

    &__buttons {
      margin: 0 15px 0 auto;
      display: none;
    }

    &__logo {
      max-width: 450px;
    }

    &__icons {
      position: relative;
      top: initial;
      right: initial;
      margin: 0 15px 0 auto;

      .eyed {
        display: none;
      }

      a {
        width: 30px;
        height: 30px;

        &.active {
          svg {
            fill: #fff;
          }
        }

        &:hover {
          svg {
            fill: #fff;
          }
        }

        svg {
          width: 30px;
          height: 23px;
        }
      }
    }

    .menu-button {
      display: flex;
      flex-shrink: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &__logo {
      max-width: 300px;

      img {
        width: 60px;
        margin-right: 15px;
      }

      h2 {
        font-size: 12px;
      }
    }

    &__buttons {
      display: none;
    }
  }
}

.authorized {
  display: flex;
  align-items: center;

  &__content {
    max-width: 100px;
    font-size: 14px;
  }

  &__links {
    font-size: 12px;
    margin-top: 5px;

    a {
      color: rgba(#fff, 0.5);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__avatar {
    flex-shrink: 0;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ffffff;
    background: #fff;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      height: 100%;
    }
  }
}

.mobile-menu {
  position: absolute;
  top: 126px;
  left: 0;
  right: 0;
  z-index: 20;
  background: #fff;
  display: none;

  .contacts-module {
    flex-wrap: wrap;
    justify-content: flex-start;

    & > * {
      margin-left: 0;
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .authorized__content {
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    top: 83px;
  }

  &__buttons {
    background: #2d2571;
    padding: 10px;

    & > * {
      margin: 5px;
    }

    a {
      color: #fff;
    }
  }

  &__nav {
    a {
      padding: 15px 15px;
      display: block;
      font-size: 16px;
      color: #000;
    }

    li {
      border-bottom: 1px solid rgba(55, 55, 124, 0.15);

      &:last-child {
        border: none;
      }

      &.active {
        a {
          color: #8a248f;
        }
      }
    }
  }

  &.open {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

// .nav {
//   width: 100%;
//   background: #fff;
//   border-top: 1px solid rgb(199, 199, 199);

//   ul {
//     display: flex;
//     align-items: center;
//   }

//   li {
//     flex-grow: 1;
//     text-align: center;
//     border-right: solid 1px rgb(199, 199, 199);

//     &:first-child {
//       border-left: solid 1px rgb(199, 199, 199);
//     }
//   }

//   a {
//     display: block;
//     padding: 10px;
//     color: #000;
//     font-size: 18px;

//     &:hover {
//       color: #E5306F;
//     }
//   }

//   @include media-breakpoint-down(lg) {
//     display: none;
//   }
// }

.intro {
  position: relative;
  background: url("../img/intro/bg.jpg") center 0 no-repeat;
  background-size: 100% 100%;
  padding-bottom: 160px;
  padding-top: 100px;

  &:after {
    content: "";
    width: 100%;
    height: 87px;
    display: block;
    background: url("../img/intro/waves.png") bottom center no-repeat;
    background-size: contain !important;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  &__row {
    display: flex;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    //width: 100%;
    margin: -15px;

    li {
      width: 33.33333%;
      padding: 15px;
    }
  }

  @include media-breakpoint-down(md) {
    //background-size: cover;

    &__list {
      width: auto;
      margin: 0 -15px;

      li {
        width: 50%;
        padding: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 60px;

    &__list {
      margin: 0;
      width: 100%;

      li {
        width: 100%;
        padding: 0;
        margin-bottom: 15px;
      }
    }
  }
}

.page-intro {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -$header-height;
  padding-bottom: 100px;
  min-height: 700px;
  padding-bottom: 50px;
  padding-top: $header-height + 20px;
  color: #fff;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url("../img/intro/bg.jpg") center no-repeat;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin-bottom: 30px;
    max-width: 800px;
    font-size: 37px;

    small {
      display: block;
      font-weight: 400;
      font-size: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__desc {
    margin-bottom: 50px;
    max-width: 625px;
    font-size: 18px;
    line-height: 1.5;
    height: 81px;
    display: block;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .breadcrumbs {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    span:last-child {
      color: #d6d6d6;
    }

    &__separator {
      color: #fff;
    }

    a {
      color: #fff;
    }
  }

  .time {
    color: #fff;
    font-size: 17px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .age {
    color: #fff;
    font-size: 32px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .rating {
    margin-left: -7px;
    margin-right: -7px;

    span {
      width: 30px;
      height: 30px;
      margin: 0 7px;
    }
  }

  .btn {
    border-radius: 0;
    font-size: 15px;
    text-transform: uppercase;
    height: 50px;
  }

  @include media-breakpoint-down(sm) {
    min-height: auto;

    &__title {
      margin-bottom: 10px;
      font-size: 28px;

      small {
        font-size: 20px;
      }
    }

    &__desc {
      font-size: 16px;
      height: auto;
    }

    .age {
      font-size: 18px;
    }

    .rating {
      margin-left: -5px;
      margin-right: -5px;

      span {
        width: 20px;
        height: 20px;
        margin: 0 5px;
      }
    }

    .breadcrumbs,
    .age,
    .time,
    &__desc {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.category-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(88, 71, 168, 0.5);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  border-radius: 5px;
  padding-right: 15px;
  color: #fff;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;

  &__icon {
    margin-right: 15px;
    width: 40%;
    flex-shrink: 0;
    display: block;
    overflow: hidden;

    img {
      max-width: 100%;
      display: block;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    color: #fff;
    background: rgba(#e5306f, 0.8);

    img {
      transform: scale(1.05);
    }
  }

  @include media-breakpoint-down(xl) {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 15px;

    &__icon {
      margin-right: 20px;
    }
  }
}

.content-and-media {
  $parent: &;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    text-transform: none !important;
  }

  &__content {
    width: 50%;

    .typography {
      font-size: 17px;
      line-height: 1.8;

      @include media-breakpoint-down(xl) {
        font-size: 16px;
      }

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }

  &__comment {
    width: 710px;
    height: 421px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15% 5%;
    line-height: 1.48;
    background: url("../img/main/comment.png") center no-repeat;
    background-size: contain;
    text-align: center;
    position: absolute;
    bottom: 87%;
    left: 61%;
    font-weight: 600;
    letter-spacing: 0;
    font-size: 26px;
    font-style: italic;

    p {
      background-image: linear-gradient(133deg, #061290 0%, #e7416d 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include media-breakpoint-down(xl) {
      width: 590px;
      height: 417px;
      font-size: 22px;
    }

    @include media-breakpoint-down(md) {
      width: 440px;
      height: 356px;
      font-size: 17px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      height: 85%;
      font-size: 100%;
      right: 0;
      left: initial;
      padding: 0 8% 5%;
    }

    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }
  }

  .module-title {
    margin-bottom: 30px;
  }

  &__media {
    width: 50%;
    text-align: center;
    position: relative;
    padding-right: 60px;

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  &__title {
    color: $color-1;
    font-size: 37px !important;
    font-weight: 500 !important;

    @include media-breakpoint-down(xl) {
      font-size: 30px !important;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px !important;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    &--media-comment {
      padding-top: 22%;
    }

    &--media-right {
      #{$parent}__media {
        order: 2;
      }

      #{$parent}__content {
        order: 1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__row {
      margin: 30px 0;
    }

    &__media {
      padding-right: 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;

    &__row {
      flex-wrap: wrap;
    }

    &__media {
      width: 100%;
      order: -1 !important;
      margin-bottom: 30px;
      padding-right: 0;
    }

    &__content {
      width: 100%;
      text-align: center;
    }
  }

  @include media-breakpoint-down(xs) {
    &__row {
      &--media-comment {
        padding-top: 15%;
      }
    }
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: -10px !important;
  margin-right: -10px !important;

  li {
    margin: 5px 10px;
  }

  img {
    height: 25px;
    display: block;
    max-width: 35px;
  }

  a {
    transition: all 0.2s ease;
    display: block;

    &:hover {
      opacity: 0.8;
      transform: scale(0.95);
    }
  }
}

.footer {
  margin-top: auto;
  background: #1c164d;
  color: #fff;

  &__logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -10px;

    & > * {
      margin: 10px;
    }

    a {
      display: block;
    }
    li {
      opacity: 0.3;

      &:hover {
        opacity: 0.8;
      }
    }
    img {
      max-width: 100px;
      height: 70px;
      display: block;
    }
  }

  &__logo {
    img {
      display: block;
      max-width: 100px;
    }
  }

  &__nav {
    margin-bottom: 30px;
    margin-left: -10px;
    margin-right: -10px;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-down(xl) {
        font-size: 14px;
      }
    }

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      li {
        @include media-breakpoint-down(lg) {
          flex-grow: initial;
        }
      }

      &.light {
        opacity: 0.5;

        a {
          font-weight: 300;
        }
      }
    }

    li {
      margin: 5px 10px;
    }
  }

  &__links {
    margin: 0 -10px 10px -10px !important;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 5px 10px;
    }

    a {
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;

      & > * {
        margin: 0 0 15px 0;
      }
    }
  }

  &__top {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: flex-start;
  }

  &__content {
    padding-left: 60px;
  }

  &__copyright {
    font-size: 12px;
    margin-right: 15px;
    color: rgba(#fff, 0.2);
  }

  .btn {
    &--line-blue {
      text-transform: none;
      font-weight: 600;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-top: 1px solid rgba(#fff, 0.1);
  }

  @include media-breakpoint-down(md) {
    &__top {
      padding-top: 40px;
    }
  }

  @include media-breakpoint-down(sm) {
    &__top {
      flex-wrap: wrap;
      padding-bottom: 30px;
    }

    .social {
      width: 100%;
    }

    &__bottom {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }

    &__nav {
      margin-top: 15px;
      display: flex;

      ul {
        width: 50%;
      }

      li {
        width: 100%;
      }
    }
  }
}

.feedback {
  background-image: linear-gradient(175deg, #edb429 2%, #ff2567 89%);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-weight: 600;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-110px) translateX(-25px) rotate(-90deg);
  transform-origin: right;
  padding: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  z-index: 15;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    padding: 10px 15px;
    top: initial;
    bottom: 10px;
    transform: translateY(-110px) translateX(-15px) rotate(-90deg);
  }

  &:hover {
    color: #fff;
    background-image: linear-gradient(175deg, #edb429 0, #ff2567 70%);
  }
}

.sidebar-navigation {
  .count {
    font-size: 31px;
    margin-right: 15px;
    font-weight: 300;
    background: linear-gradient(to top, #e24175 0%, #e24175 1%, #f3be32 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    height: 40px;
    flex-shrink: 0;
  }

  a {
    cursor: pointer;
  }

  ul {
    li {
      position: relative;
      border-bottom: 2px solid #fff;

      &.has-podmenu {
        & > a {
          padding-right: 55px;

          &:after {
            content: "";
            width: 11px;
            height: 11px;
            display: block;
            border-bottom: 3px solid rgba($color-1, 0.2);
            border-right: 3px solid rgba($color-1, 0.2);
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%) rotate(45deg);
            transition: all 0.3s ease;
          }
        }

        &.active {
          a {
            &:after {
              border-color: #000;
              transform: translateY(-50%) rotate(-133deg);
            }
          }
        }
      }

      > a {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        letter-spacing: 0.02em;
        color: #000000;
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;

        img {
          margin-right: 25px;
        }

        @include media-breakpoint-down(xl) {
          font-size: 14px;
        }
      }

      ul {
        display: none;
        padding-bottom: 15px;

        li {
          border: none;
          padding-left: 80px;
          margin-bottom: 15px;

          @include media-breakpoint-down(lg) {
            padding-left: 15px;
          }

          a {
            color: $color-1;
            font-size: 13px;
            padding: 0 30px 0 30px;
            position: relative;

            &:before {
              content: "";
              width: 13px;
              height: 1px;
              display: block;
              background: $color-1;
              position: absolute;
              top: 9px;
              left: 0;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  li {
    &:hover {
      a {
        color: $color-1;
      }
    }

    &.active {
      & > a {
        background: #5847a8;

        .count {
          color: #868ab2;
          background: none;
          background-clip: initial;
          -webkit-text-fill-color: #868ab2;
          -webkit-background-clip: initial;
        }

        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }

    &:before {
      content: "";
      width: 2px;
      height: 2px;
      background: $color-1;
      position: absolute;
      top: 50%;
      right: 100%;
      display: block;
      margin-right: 15px;
      transform: translateY(-50%);
    }
  }

  &--sm {
    padding-bottom: 20px;

    ul {
      li {
        border: none;

        & > a {
          padding: 8px 15px;
        }
      }
    }
  }
}

.page {
  margin-bottom: auto;
  display: flex;
  flex-grow: 1;
  background: #fff;
  $parent: &;

  .inner-wrapper-sticky {
    padding-top: 30px;
  }

  &__sidebar {
    background: #edf1fa;
    position: relative;
    width: 280px;
    flex-shrink: 0;

    &:before {
      content: "";
      width: 99999px;
      height: 100%;
      background: inherit;
      display: block;
      position: absolute;
      right: 100%;
      top: 0;
    }
  }

  &__content {
    padding-left: 60px;
    width: calc(100% - 280px);
    padding-top: 25px;
    padding-bottom: 80px;

    & > * {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--full {
      width: 100%;
      padding-left: 0;
    }

    @include media-breakpoint-down(xl) {
      padding-left: 50px;

      &--full {
        padding-left: 0;
      }
    }
  }

  .container {
    display: flex;
  }

  &--lk {
    #{$parent}__sidebar {
      width: 215px;
    }

    #{$parent}__content {
      width: calc(100% - 215px);
    }

    .sidebar-navigation ul li > a {
      padding: 15px 15px;
    }
  }

  @include media-breakpoint-down(lg) {
    &__content {
      width: calc(100% - 280px);

      &--full {
        width: 100%;
        padding-left: 0;
      }
    }

    &__sidebar {
      width: 280px;
    }
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;

    .inner-wrapper-sticky {
      padding-top: 0;
    }

    .container {
      flex-wrap: wrap;
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }

    &__sidebar {
      width: 100%;

      &:after {
        content: "";
        width: 99999px;
        height: 100%;
        background: inherit;
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
      }
    }

    .sidebar-navigation {
      display: none;
    }

    &--lk {
      #{$parent}__sidebar {
        width: 100%;
      }

      #{$parent}__content {
        width: 100%;
      }
    }
  }
}

.sidebar-nav-toggle {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  color: $color-1;
  font-weight: 600;

  &:after {
    content: "";
    width: 11px;
    height: 11px;
    display: block;
    top: 50%;
    position: absolute;
    right: 30px;
    border-bottom: 3px solid rgba(#000, 0.5);
    border-right: 3px solid rgba(#000, 0.5);
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease;
    margin-left: 10px;
  }

  &.active {
    &:after {
      border-color: #000;
      transform: translateY(-50%) rotate(-133deg);
    }
  }
}

.courses {
  $parent: &;

  .webinar,
  .course-item {
    height: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
    margin-bottom: 15px;
  }

  &__col {
    width: 50%;
    padding: 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &:last-child {
    #{$parent}__row {
      margin-bottom: 0;
    }

    #{$parent}__col {
      padding-bottom: 0;
    }
  }
}

.breadcrumbs {
  margin-bottom: 12px;
  font-size: 12px;

  span:last-child {
    color: #747474;
  }

  &__separator {
    margin: 0 5px;
    color: $color-1;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.search-module {
  margin-bottom: $mb;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden;
  border: solid 1px transparent;

  &__row {
    display: flex;
  }

  label {
    height: 52px;
    width: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    flex-shrink: 0;

    &:hover {
      svg {
        fill: rgba($color-1, 0.5);
      }
    }

    @include media-breakpoint-down(sm) {
      height: 42px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    display: block;
    fill: rgba(#000, 0.1);
    transition: all 0.2s ease;
  }

  button {
    font-size: 15.3px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    padding: 0 30px;
    color: #ffffff;
    flex-shrink: 0;
    background-image: linear-gradient(204deg, #edb429 20%, #ff2567 71%);

    &:hover {
      background-image: linear-gradient(204deg, #edb429 10%, #ff2567 60%);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 15px;
      font-size: 14px;
    }
  }

  input {
    flex-grow: 1;
    font-size: 16px;
    height: 52px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 42px;
    }

    &::placeholder {
      font-style: italic;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 0;
      }
    }
  }

  &.error {
    border-color: red;

    label {
      svg {
        fill: rgba(red, 0.5);
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.course-item,
.webinar {
  $parent: &;
  display: flex;
  flex-direction: column;
  background: rgb(217, 226, 243);

  &__image {
    overflow: hidden;
    display: block;

    img {
      display: block;
      width: 100%;
      transition: all 0.2s ease;
    }
  }

  &__subtitle {
    margin-bottom: 5px;
    display: block;
    color: #9da4d0;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__content {
    padding: 20px 35px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 17.6px;
    font-weight: 500;
    text-decoration: underline;
    color: $color-1;
    display: block;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 13px;
    color: #000;
    margin-bottom: 20px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    .time {
      justify-content: flex-end;
    }

    .rating,
    .time {
      width: 80px;
    }
  }

  &:hover {
    #{$parent}__image {
      img {
        transform: scale(1.1);
      }
    }

    #{$parent}__title {
      text-decoration: none;
      color: rgb(255, 37, 103);
    }
  }

  @include media-breakpoint-down(lg) {
    &__content {
      padding: 20px 25px;
    }
  }
}

.bookmark {
  background-image: linear-gradient(
    -156deg,
    #edb429 0%,
    #edb429 1%,
    #ff2567 91%,
    #ff2567 100%
  );
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 10px;
  &--dark {
    background-image: linear-gradient(
      -156deg,
      #073ba7 0%,
      #073ba7 1%,
      #af25ff 91%,
      #af25ff 100%
    );
  }
}
.webinar {
  &__bottom {
    .time {
      width: 50%;
    }
  }
}
.age {
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0.16em;
  color: #ff2a66;
}
.rating {
  display: flex;
  align-items: center;
  span {
    margin: 0 3px;
    width: 14px;
    height: 14px;
    display: block;
    background: url("../img/icons/star.svg") center no-repeat;
    background-size: cover;
    &.active {
      background: url("../img/icons/star-gr.svg") center no-repeat;
      background-size: cover;
    }
  }
}
.time {
  font-size: 13px;
  line-height: 1;
  color: #000;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__content {
    width: calc(100% - 30px);
  }
  &__desc {
    font-size: 12px;
    width: 100%;
    color: #d7d7d7;
    display: block;
    margin-bottom: 5px;
  }
  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background: url("../img/icons/time.svg") center no-repeat;
    background-size: contain;
    margin-right: 5px;
  }
  &--old {
    color: rgba(#000, 0.6);
    &:before {
      background: url("../img/icons/time-gray.svg") center no-repeat;
      background-size: contain;
    }
  }
}
.page-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
  .date {
    font-size: 0.5em;
    color: #7c6ab9;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
  }
  & > * {
    margin: 5px;
  }
  .btn {
    border-radius: 0;
    font-size: 13px;
    letter-spacing: 1.02px;
    text-transform: uppercase;
    padding: 0.9em 35px;
  }
  h1 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    color: #000;
    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }
}
.category-title {
  font-size: 29px;
  font-weight: 500;
  color: $color-1;
  margin-bottom: 20px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}
.documents {
  &__title {
    font-size: 22px;
    color: #0010ac;
    font-weight: 500;
    margin-bottom: 15px;
  }
  li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.document {
  display: flex;
  border: solid 3px #d1d2eb;
  padding: 15px 25px;
  position: relative;
  font-size: 17px;
  color: #000;
  font-weight: 500;
  &:before {
    content: "";
    width: 19px;
    height: 24px;
    display: block;
    background: url("../img/icons/doc.svg") center no-repeat;
    background-size: contain !important;
    margin-right: 20px;
    flex-shrink: 0;
  }
  &__name {
    padding-right: 10px;
  }
  &__size {
    font-style: italic;
    opacity: 0.5;
    color: #000000;
    margin-left: auto;
    flex-shrink: 0;
    white-space: nowrap;
  }
  &:hover {
    border: solid 3px rgb(177, 179, 219);
  }
}
.p-block {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.faq,
.search-results {
  &__title {
    color: $color-1;
    @extend .h2;
    margin-bottom: 30px;
  }
  &__item {
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom: 1px solid #d4d5ec;
    &:last-child {
      border: none;
      padding-bottom: 10px;
    }
    &:first-child {
      padding-top: 10px;
    }
  }
}
.search-results {
  &__title {
    display: block;
    text-decoration: underline;
  }
}
.reviews {
  .review {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 40px;
  }
}
.review {
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
  position: relative;
  &__row {
    padding: 35px;
    background: #fff;
    position: relative;
    display: flex;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 90px;
    width: 35px;
    height: 35px;
    background: #fff;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
    transform: rotate(-45deg);
    margin-top: -20px;
    z-index: 1;
  }
  &__avatar {
    width: 157px;
    flex-shrink: 0;
    img {
      max-width: 100%;
      display: block;
    }
  }
  &__content {
    padding-left: 55px;
  }
  &__name {
    color: $color-1;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__date {
    color: #838383;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  @include media-breakpoint-down(md) {
    &__content {
      padding-left: 35px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__row {
      flex-wrap: wrap;
      padding: 20px;
    }
    &:after {
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    .typography {
      font-size: 14px;
    }
    &__avatar {
      margin-bottom: 15px;
    }
    &__content {
      padding: 0;
    }
  }
}
.pagination {
  display: flex;
  a {
    font-size: 16px;
    color: #000;
    line-height: 1;
    background: #fff;
    border: 2px solid #ccc;
    padding: 6px 10px;
    display: block;
    &:hover {
      background: #ccc;
      border: 2px solid #ccc;
    }
    &.active {
      border: 2px solid #5847a8;
      color: #fff;
      background: #5847a8;
    }
    margin-right: 10px;
  }
}
.courses-filter {
  margin-top: -5px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 30px;
  .filter-button {
    margin: 5px 10px;
  }
}
.filter-button {
  display: inline-flex;
  cursor: pointer;
  span {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: #073ba7;
    padding: 8px 16px;
    text-decoration: underline;
    border: solid 1px transparent;
    &:hover {
      color: #000;
    }
    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }
  input {
    display: none;
    &:checked + span {
      text-decoration: none;
      color: #a5b4e6;
      border-color: rgba(#3462c1, 0.5);
      &:hover {
        color: #a5b4e6;
      }
    }
  }
}
.contacts {
  padding-right: 50px;
  &__icon {
    width: 85px;
    height: 85px;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    flex-shrink: 0;
    img {
      width: 35px;
    }
  }
  &__text {
    a {
      &:hover {
        color: $color-1;
      }
    }
    a,
    p {
      font-size: 20px;
      color: #000;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__title {
    font-size: 18px;
    color: $color-1;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__content {
    padding-top: 15px;
  }
  &__item {
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-right: 0;
    &__item {
      margin-top: 0;
      margin-bottom: 30px;
    }
    &__icon {
      margin-right: 30px;
    }
    &__text {
      p,
      a {
        font-size: 16px;
      }
    }
  }
}
.form-block {
  text-align: center;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  background-color: #ffffff;
  padding: 55px;
  max-width: 580px;
  &--contacts {
    margin-left: auto;
    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-top: 60px;
      margin-bottom: 30px;
      max-width: 100%;
    }
  }
  .btn {
    margin-top: 15px;
  }
  .field {
    width: 100%;
    max-width: 375px;
    margin: 0 auto 15px;
    text-align: center;
    input {
      height: 30px;
    }
    textarea {
      min-height: 80px;
    }
    textarea,
    input {
      text-align: center;
      padding: 0;
    }
  }
  &__title {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 35px;
  }
  @include media-breakpoint-down(sm) {
    padding: 30px;
    &__title {
      font-size: 24px;
    }
  }
}
.block {
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  background-color: #ffffff;
  padding: 40px;
  margin-bottom: 60px;
  $block-padding: 40px;
  $parent: &;
  &--table {
    padding: 20px;
  }
  &__title {
    color: $color-1;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 60px;
  }
  .title-count {
    margin-top: -$block-padding - 30px;
  }
  &--gr-border {
    background-image: linear-gradient(
      -156deg,
      #cc3c8d 0%,
      #cf3c87 1%,
      #2064c0 91%,
      #215dbc 100%
    );
    box-shadow: none;
    padding: 20px;
    #{$parent}__content {
      background: #fff;
      padding: 30px;
    }
  }
  &--not-shadow {
    box-shadow: none;
    padding: 0;
  }
  & > * {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .block-slider {
    margin: -40px;
    margin-top: 40px;
    overflow: hidden;
  }
  @include media-breakpoint-down(md) {
    .title-count {
      margin-top: 0px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 20px 20px 35px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;
    margin-top: 40px;
    &--table {
      padding: 0px;
      overflow: auto;
    }
    .block-slider {
      margin: 30px -20px -35px -20px;
    }
    .typography {
      font-size: 14px;
    }
    .title-count {
      margin-top: 0px;
    }
    &:first-child,
    &:first-of-type {
      margin-top: 0;
    }
  }
}
.buttons-row {
  display: flex;
  margin: -10px;
  .btn {
    flex-grow: 1;
    padding-left: 10px;
    height: 50px;
    line-height: 1.2;
    padding-right: 10px;
    margin: 10px;
  }
  &--not-grow {
    .btn {
      flex-grow: 0;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    .btn {
      height: auto;
      width: 100%;
    }
  }
}
.title-count {
  display: inline-flex;
  align-items: flex-end;
  color: $color-1;
  font-size: 39px;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 3px solid #b9bdd9;
  $parent: &;
  &__title {
    line-height: 1;
    font-size: inherit;
    font-weight: 300;
    text-transform: uppercase;
  }
  &__count {
    color: #b9bdd9;
    font-weight: 100;
    line-height: 0.8;
    display: block;
    letter-spacing: -0.08em;
    font-size: 384.62%;
    margin-right: 20px;
  }
  &--rows {
    flex-wrap: wrap;
    border: none;
    padding-bottom: 0;
    #{$parent}__count {
      width: 100%;
      padding-bottom: 25px;
    }
    #{$parent}__title {
      padding-top: 25px;
      border-top: 3px solid #b9bdd9;
    }
  }
  @include media-breakpoint-down(xl) {
    font-size: 33px;
  }
  @include media-breakpoint-down(md) {
    font-size: 24px;
    &__count {
      font-size: 300%;
      margin-right: 15px;
    }
  }
  @include media-breakpoint-down(xs) {
    font-size: 20px;
  }
}
.persons {
  & > * {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.person {
  display: flex;
  align-items: center;
  font-size: 17px;
  &__image {
    width: 190px;
    margin-right: 40px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__name {
    color: $color-1;
    font-size: 22px;
    margin-bottom: 5px;
  }
  &__content {
    width: calc(100% - 190px - 40px);
    line-height: 1.6;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    &__image {
      margin-bottom: 15px;
      max-width: 100px;
    }
    &__desc {
      font-size: 14px;
    }
    &__content {
      width: 100%;
    }
  }
}
.beginner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 720px;
  margin: 0 auto;
  img {
    display: block;
    width: 60px;
    margin-right: 30px;
    flex-shrink: 0;
  }
  &__title {
    color: $color-1;
    font-size: 27px;
    line-height: 1.2;
    max-width: 285px;
    position: relative;
    margin-right: auto;
    small {
      display: block;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 10px;
      position: absolute;
      bottom: 100%;
      @include media-breakpoint-down(sm) {
        position: relative;
        margin-top: 15px;
      }
    }
  }
  .btn {
    margin-left: 15px;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 0;
    &__title {
      max-width: calc(100% - 90px);
      font-size: 20px;
    }
    .btn {
      margin: 30px 0 0 0;
      width: 100%;
    }
  }
  @include media-breakpoint-down(xs) {
    img {
      width: 30px;
      margin-right: 20px;
    }
    &__title {
      max-width: calc(100% - 50px);
      font-size: 20px;
    }
    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.block-slider {
  img {
    display: block;
    max-width: 100%;
  }
  * {
    vertical-align: top;
  }
  .slick-arrow {
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: #fff;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:after {
      content: "";
      width: 18px;
      height: 28px;
      display: block;
      background: url("../img/icons/arrow.svg") center no-repeat;
      background-size: contain;
      margin-left: -30px;
    }
    &:hover {
      &:after {
        background: url("../img/icons/arrow-gr.svg") center no-repeat;
        background-size: contain;
      }
    }
    &.slick-prev {
      left: 0;
      transform: rotate(-180deg) translateY(50%) translateX(50%);
    }
    &.slick-next {
      right: 0;
      transform: translateY(-50%) translateX(50%);
    }
    @include media-breakpoint-down(sm) {
      width: 60px;
      height: 60px;
      &:after {
        width: 12px;
        height: 20px;
        margin-left: -20px;
      }
    }
  }
}
.block-carousel {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  img {
    display: block;
    width: 100%;
  }
  * {
    vertical-align: top;
  }
  a {
    display: block;
    &:hover {
      opacity: 0.9;
    }
  }
  .slick-slide {
    padding: 5px;
  }
  .slick-arrow {
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 28px;
    display: block;
    background: url("../img/icons/arrow.svg") center no-repeat;
    background-size: contain;
    &:hover {
      background: url("../img/icons/arrow-gr.svg") center no-repeat;
      background-size: contain;
    }
    &.slick-prev {
      left: 0;
      transform: rotate(-180deg) translateY(50%);
    }
    &.slick-next {
      right: 0;
    }
  }
}
.certificate {
  display: flex;
  .title-count {
    margin-top: 0;
  }
  &__image {
    width: 50%;
    flex-shrink: 0;
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__content {
    padding-top: 30px;
    padding-left: 45px;
    height: 50%;
  }
  @include media-breakpoint-down(sm) {
    .title-count {
      border-bottom: 3px solid #b9bdd9;
      padding-bottom: 25px;
      &__count {
        width: auto;
        padding-bottom: 0;
      }
      &__title {
        border: none;
        padding-top: 0;
      }
    }
    flex-wrap: wrap;
    &__content {
      padding-left: 0;
      padding-top: 0;
      margin-bottom: 30px;
    }
    &__image {
      order: 3;
      img {
        width: 300px;
        max-width: 100%;
      }
    }
    &__content,
    &__image {
      width: 100%;
    }
  }
}
.review-block {
  padding-bottom: 40px;
  padding-top: 40px;
  border-bottom: 1px solid #e8e8e8;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__name {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }
  &__type {
    font-size: 14px;
    font-weight: 300;
  }
  &__title {
    text-decoration: underline;
    font-size: 20px;
    display: block;
    margin-bottom: 15px;
  }
  &__date {
    text-align: right;
    color: #9ca1c7;
    font-size: 14px;
    flex-shrink: 0;
    margin-left: 30px;
  }
  .typography {
    font-size: 15px;
    line-height: 1.6;
  }
  @include media-breakpoint-down(sm) {
    &__top {
      flex-wrap: wrap;
    }
    &__date {
      margin: 0px 0 30px 0;
    }
  }
}
.video-module {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(#494a90, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  &:after {
    content: "";
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url("../img/icons/youtube-gr.svg") center no-repeat;
    transform: translateY(-50%) translateX(-50%);
    z-index: 3;
    transition: all 0.5s ease;
  }
  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.5s ease;
  }
  &:hover {
    &:after {
      transform: translateY(-50%) translateX(-50%) scale(1.05);
    }
    img {
      transform: translateY(-50%) translateX(-50%) scale(1.02);
    }
  }
}
.answers {
  &__date {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    display: block;
    margin-bottom: 10px;
  }
  &__content {
    font-size: 14px;
  }
  &__item {
    border-bottom: 1px solid rgba(#b9bdd9, 0.5);
    padding-top: 20px;
    padding-bottom: 20px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}
.avatar-upload {
  width: 224px;
  margin: 45px auto 0;
  &__image {
    width: 224px;
    height: 266px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #edf1ff;
    img {
      display: block;
      max-height: 100%;
    }
    margin-bottom: 15px;
  }
  &__button {
    width: 100%;
    .btn {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    input {
      display: none;
    }
  }
}
.status-strong {
  font-weight: 700;
  &--light {
    font-weight: 400;
  }
  &--green {
    color: #39bb00;
  }
  &--red {
    color: #f95452;
  }
}
.block-table {
  font-size: 15px;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &__name {
    opacity: 0.5;
    color: #000000;
    font-size: 14px;
  }
  &--home-work {
    tbody {
      tr {
        &:nth-child(odd) {
          td {
            border-bottom: 1px solid #f1f1f1;
          }
        }
        &:nth-child(2n) {
          td {
            padding: 8px 10px;
          }
        }
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #0010ac;
    display: block;
    max-width: 260px;
    min-width: 180px;
  }
  &__icons {
    margin: -9px;
    & > * {
      margin: 9px;
    }
    a {
      display: inline-block;
      transition: all 0.2s ease;
      &:hover {
        transform: translateY(-3px);
      }
      img {
        display: block;
      }
    }
  }
  &__date {
    color: $color-1;
    white-space: nowrap;
  }
  td,
  th {
    border: none;
    padding: 17px 10px;
  }
  td {
    border-bottom: 1px solid #eaebf7;
  }
  tr {
    &.complete {
      opacity: 0.4;
    }
    &:last-child {
      td {
        border: none;
      }
    }
  }
  th {
    font-weight: 700;
    text-align: left;
    color: rgba(#8d8d8d, 0.5);
    border-bottom: 2px solid #eaebf7;
  }
}
.count-status {
  background-color: #edf1ff;
  color: rgba(#0010ac, 0.5);
  font-size: 16px;
  font-weight: 700;
  border-radius: 19px;
  padding: 8px 14.45px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  &--blue {
    background: #0010ac;
    color: #fff;
  }
  &--red {
    background: #db3b6f;
    color: #fff;
  }
  &--orange {
    background: #ff9000;
    color: #fff;
  }
}
.links-item {
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
.site-map {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__col {
    width: 33.333%;
    padding: 0 15px;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
.news-block {
  color: #000;
  display: block;
  margin-bottom: 30px;
  &__image {
    overflow: hidden;
    display: block;
    border-radius: 15px;
    margin-bottom: 15px;
    img {
      width: 100%;
      display: block;
    }
  }
  &__title {
    font-size: 18px;
  }
  &__date {
    font-size: 12px;
    margin-bottom: 5px;
    color: #888;
  }
  &:hover {
    color: #1d174e;
    .news-block__title {
      text-decoration: underline;
    }
  }
}
@mixin bButtons() {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  & > * {
    margin: 15px;
  }
}
@mixin itemDate() {
  color: #747474;
  font-size: 45px;
  line-height: 1;
  font-family: $main-font;
  font-weight: 300;
  display: block;
  margin-bottom: 30px;
  @media (max-width: $tablet-sm) {
    font-size: 30px;
  }
}
@mixin itemTitle() {
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
}
.gallery-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
  li {
    width: 33.333333%;
    padding: 2px;
  }
  img {
    display: block;
    max-width: 100%;
    transition: all 0.2s ease;
  }
  a {
    display: block;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.album {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #fff;
  text-decoration: none;
  $parent: &;
  &:hover {
    color: #fff;
  }
  &__name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 30px;
  }
  .btn {
    flex-shrink: 0;
  }
  img {
    display: block;
    max-width: 100%;
  }
  &__date {
    font-weight: 300;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 40px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#000, 0.5);
  }
  &--intro {
    #{$parent}__bottom {
      text-align: center;
      justify-content: center;
      @media (max-width: $mobile) {
        padding: 15px;
      }
    }
    #{$parent}__name {
      font-size: 20px;
      margin-right: 0;
      @media (max-width: $mobile) {
        font-size: 16px;
      }
    }
  }
  @media (max-width: $mobile) {
    &__bottom {
      position: relative;
      order: 3;
      flex-wrap: wrap;
      background: rgba(#6e6ead, 1);
      padding: 25px;
    }
    &__name {
      width: 100%;
    }
    .btn {
      margin-top: 20px;
    }
  }
}
.news-module {
  display: flex;
  background: #edf1fa;
  text-decoration: none;
  color: #000;
  border-radius: 15px;
  overflow: hidden;
  $parent: &;
  &__content {
    padding: 30px 50px 30px 35px;
    flex-grow: 1;
    // min-width: 60%;
    min-width: calc(100% - 260px);
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    color: #e73070;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
  }
  &__date {
    color: rgba(#000, 0.3);
    margin-bottom: 10px;
    font-size: 12px;
  }
  &__desc {
    color: rgba(#000, 1);
    font-size: 14px;
    line-height: 1.5;
    display: block;
  }
  &__image {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    max-width: 40%;
    flex-shrink: 0;
    img {
      display: block;
      width: 100%;
      transition: all 0.2s ease;
    }
  }
  &:hover {
    #{$parent}__image {
      img {
        transform: scale(1.1);
      }
    }
    #{$parent}__title {
      text-decoration: underline;
    }
  }
  @media (max-width: $mobile) {
    // flex-wrap: wrap;
    align-items: center;
    &__title {
      font-size: 14px;
    }
    &__image {
      margin-bottom: auto;
      img {
        height: auto;
        max-width: 100%;
      }
    }
    &__date {
      margin-bottom: 0;
    }
    &__desc {
      display: none;
    }
    &__content {
      padding: 15px;
    }
  }
}
.doc {
  padding: 20px;
  font-size: 14px;
  border-radius: 15px;
  background: #fff;
  border: solid 3px #eeeeee;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  &__content {
    padding-top: 20px;
  }
  &__date {
    color: #22415e;
    font-size: 14px;
    font-weight: 300;
    padding-left: 15px;
    flex-shrink: 0;
  }
  &__title {
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    display: flex;
    &:before {
      content: "";
      width: 19px;
      height: 24px;
      display: block;
      background: url("../img/icons/doc.svg") center no-repeat;
      background-size: contain !important;
      margin-right: 20px;
      flex-shrink: 0;
    }
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  .btn {
    margin-top: 20px;
    font-size: 11px;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &__right {
    display: flex;
    align-items: center;
    & > * {
      margin: 10px !important;
    }
    @media (max-width: $mobile) {
      width: 100%;
      flex-wrap: wrap;
      margin-top: 10px;
      .doc__date {
        width: auto;
        margin-left: 0;
      }
    }
  }
  &--not-desc {
    padding: 10px 20px;
    .doc__top {
      border: none;
      padding-bottom: 0;
    }
    .btn {
      margin-top: 0;
    }
  }
  @media (max-width: $mobile) {
    &__top {
      flex-wrap: wrap;
    }
    &__date {
      padding-left: 0;
      width: 100%;
      margin-top: 15px;
    }
  }
}
.news-intro {
  display: flex;
  margin-bottom: 60px !important;
  &__image {
    width: 50%;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
    @media (max-width: $tablet) {
      width: 40%;
    }
  }
  // align-items: center;
  &__content {
    width: 50%;
    padding: 60px 60px;
    margin-top: 30px;
    position: relative;
    background: #fff;
    @media (max-width: $tablet) {
      padding: 40px 40px;
      width: 60%;
    }
    &:after {
      content: "";
      height: calc(100% + 30px);
      width: 10px;
      background: #e73070;
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
    }
    &:before {
      content: "";
      width: calc(100% + 30px);
      height: 10px;
      background: #e73070;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__title {
    margin-bottom: 15px;
    font-size: 23px;
    color: #000;
  }
  &__date {
    @include itemDate();
    font-size: 17px;
    margin-bottom: 15px;
  }
  &__desc {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #000;
    @media (max-width: $mobile) {
      text-align: left;
    }
  }
  .ya-share2 {
    margin-top: 30px;
  }
  @media (max-width: $mobile) {
    flex-wrap: wrap;
    &__content {
      width: 100%;
      margin-top: -10px;
      padding: 30px 30px 30px 15px;
    }
    &__image {
      width: 100%;
    }
  }
}
.module-title {
  font-size: 30px;
  margin-bottom: 60px;
}
.date {
  @include itemDate();
}
.news-item {
  color: #000;
  display: block;
  text-decoration: none;
  $parent: &;
  &__date {
    @include itemDate();
  }
  &__title {
    @include itemTitle();
  }
  .typography {
    font-size: 14px;
    big {
      font-size: 18px;
      font-weight: 700;
    }
  }
  &:hover {
    color: #3f3f3f;
    #{$parent}__title {
      text-decoration: underline;
    }
  }
}
.bg-dotted {
  background-image: url(../img/dot-pattern.svg);
}
.news {
  background: #edf1fa;
  padding-bottom: 80px;
  padding-top: 80px;
  margin: 0;
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__col {
    width: 50%;
    padding: 0 15px;
  }
  .module-title {
    text-align: center;
  }
  .news-item {
    max-width: 500px;
  }
  &__buttons {
    @include bButtons();
  }
  @media (max-width: $mobile) {
    padding-bottom: 40px;
    padding-top: 40px;
    .news-item {
      text-align: center;
    }
    &__row {
      margin: 0;
    }
    &__buttons {
      margin-top: 30px;
    }
    &__col {
      width: 100%;
      padding: 0;
      &:last-child {
        display: none;
      }
    }
  }
}
.photo-report {
  $parent: &;
  // border-top: 1px solid #29083d;
  .module-title {
    margin-bottom: 15px;
  }
  &__title {
    @include itemTitle();
    max-width: 420px;
  }
  .typography {
    font-size: 14px;
    max-width: 420px;
    big {
      font-size: 18px;
      font-weight: 700;
    }
  }
  &__content {
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 2px;
    &:before {
      content: "";
      width: 0;
      height: 3px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background: #cc4253;
      opacity: 0;
      transition: all 0.5s ease 1s;
    }
  }
  &.show {
    #{$parent}__content {
      &:before {
        width: 9999px;
        opacity: 1;
      }
    }
  }
  &__buttons {
    @include bButtons();
    justify-content: flex-start;
    margin-top: 20px;
  }
  &__row {
    display: flex;
    position: relative;
    &:last-child {
      margin-bottom: 2px;
    }
  }
  img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    flex-shrink: 0;
    transition: all 0.3s ease;
  }
  &__photo-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    z-index: 3;
    border: solid 1px #fff;
    font-family: $main-font;
    max-width: 150px;
    font-size: 18px;
    text-align: center;
    padding: 15px;
    font-weight: 500;
  }
  &__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 4px);
    overflow: hidden;
    margin: 2px;
    height: 540px;
    position: relative;
    &--large {
      width: 100% !important;
      left: 50% !important;
      position: relative !important;
      right: auto;
      margin-right: 0;
      margin-top: 0;
    }
    &--read-more {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(43, 15, 76, 0.66);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
      &:hover {
        #{$parent}__photo-btn {
          background: #fff;
          color: #000;
        }
      }
    }
    &:first-child {
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
    }
    &:last-child {
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: $notebook) {
    .date {
      font-size: 35px;
    }
  }
  @media (max-width: $tablet) {
    &__row {
      flex-wrap: wrap;
    }
    &__photo {
      height: 350px;
      &--large {
        left: 0 !important;
      }
    }
    &__content {
      width: 100%;
      height: auto;
      position: relative;
      align-items: center;
      text-align: center;
      padding-right: 0;
      &:before {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
  @media (max-width: $tablet-sm) {
    &__photo {
      height: auto;
      img {
        max-width: 100%;
      }
    }
  }
}
// ie
.ie10,
.ie11 {
  .loader {
    display: none !important;
  }
  .content-and-media__comment p {
    background-clip: initial;
    background: none;
  }
  .sidebar-navigation .count {
    background-clip: inherit;
    background: none;
    color: #edb429;
  }
  .sidebar-navigation img {
    height: initial;
    width: 40px;
  }
}
