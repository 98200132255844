html body .bvi-body {
    padding-top: 30px;

    .header {
        border-bottom: 1px solid #000;
        margin-bottom: 30px;

        &__icons {
            display: none !important;
        }

        &__row {
            flex-wrap: wrap;
        }

        &__nav {
            margin-left: 0;

            li {
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .container {
            .header__logo {
                width: 100%;
                max-width: 100%;
                margin-bottom: 30px;

                h2 {
                    margin-left: 30px;
                }

                img {

                    background: url('../img/logo/logo-color.png') !important;
                }
            }
        }
    }

    .document {
        &:before {
            display: none !important;
        }
    }

    .footer {
        border-top: 1px solid #000;
        margin-top: 60px;

        .footer__links {
            width: 100%;
        }

        &__row {
            flex-wrap: wrap;
        }
    }

    .feedback {
        border: solid 1px #000;
    }

    .category-item {
        border: solid 1px #000;
    }

    .review {
        border-bottom: 1px solid #000;

        &:after {
            display: none;
        }

        &:last-child {
            border: none;
        }
    }

    .btn {
        height: auto;
        border: solid 1px #000;

        svg {
            display: none;
        }

    }

    .content-and-media {
        &__row {
            &--media-comment {
                padding-top: 0;
            }
        }

        &__media {
            div {
                margin: 0 auto;
            }
        }

        &__comment {
            display: none;
        }
    }

    .intro {
        &__image {
            margin-left: 30px;
        }
    }

    .rating {
        display: none !important;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        font-size: 0 !important;
    }

    .page__content .video-module {
        padding: 0 !important;
        border: solid 1px #000;
        display: block;
        height: auto;

        &:after {
            background: url(../img/icons/youtube-gr.svg) center no-repeat !important;
        }

        &:before {
            display: none;
        }

        img {
            position: relative;
            transform: none;
            left: 0;
            top: 0;

        }
    }

    .datepicker {
        border: solid 1px #000;
    }

    .block {
        border: solid 1px #000;
        display: block;
        overflow: hidden;

        &--not-shadow {
            border: none !important;
        }
    }

    .block-carousel img {
        height: 155px !important;
    }



    .beginner__title {
        margin-left: 30px;
    }

    .title-count {
        width: 100%;
    }

    .gender__val:before {
        background: red !important;
    }

    .block-slider img {
        height: 466px;
    }

    .checkbox__content:before,
    .radio__content:before {
        background: red !important;
    }

    .title-count .title-count__count {
        font-size: 2em !important;
        line-height: 1 !important;
    }

    .typography ul li::before {
        background: #000 !important;
        height: 2px !important;
        top: .8em !important;
    }

    .page-intro {
        border-bottom: solid 1px #000;
        min-height: auto;

        .age {
            margin-bottom: 0;
        }
    }

    .sidebar-navigation {
        li {
            border-width: 1px;
        }

        span {
            margin-left: 20px;
        }

        .count {
            font-size: 20px !important;
            background: transparent !important;
        }
    }

    .block-slider .slick-arrow:after {
        background: url(../img/icons/arrow-gr.svg) center no-repeat !important;
        background-size: contain !important;
    }

    .block-carousel.slick-slider .slick-arrow {
        background: url(../img/icons/arrow-gr.svg) center no-repeat !important;
        background-size: contain !important;
    }

    .contacts__icon,
    .form-block {
        border: solid 1px #000;
    }

    .course-item,
    .webinar {
        border: solid 1px #000;
        display: block;

        &__image {
            img {
                height: 150px;
            }
        }
    }

    .modal-block {
        border: solid 1px #000;

        &__close {
            background: none !important;
            width: auto;

            &:before {
                content: 'Закрыть';
                padding: 10px 15px;
                display: block;
                border: solid 1px #000;
                background: #000;
            }
        }
    }

    .time {
        &:before {
            display: none;
        }
    }


}

html body .bvi-body[data-bvi-images=false] .bvi-img-off {
    font-size: 0 !important;
}