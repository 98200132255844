.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: none;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url("../img/main/modal-bg.jpg") center no-repeat;
  background-size: cover;
  transition: opacity 0.3s linear;
  opacity: 0;
  visibility: hidden;
  padding: 0 70px;
  overflow: auto;
  flex-flow: row wrap;
  z-index: 15;

  &.init {
    display: flex;
  }

  .modal-block {
    opacity: 0;
    transition: all 0.5s ease;
    transform: translate(-10px, 0) scale(0.95);
  }

  &.active {
    opacity: 1;
    transform: none;

    .modal-block {
      opacity: 1;
      transform: none;
    }

    visibility: visible;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal-block {
  width: 100%;
  max-width: 710px;
  margin-bottom: 65px;
  margin-top: 65px;
  background: rgba(#091b78, 0.7);
  border-radius: 15px;
  color: #fff;
  padding: 30px 60px;
  position: relative;

  &__close {
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: url("../img/main/cross.png") center no-repeat;
    display: block;
    position: absolute;
    right: 0;
    top: -50px;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
      transform: scale(0.95);
    }

    @include media-breakpoint-down(sm) {
      right: 15px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 36px;
    text-align: center;
    font-weight: 400;
  }

  &__desc {
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
    font-weight: 500;
    color: rgba(#fff, 0.5);
    margin-top: -20px;
  }

  .field {
    margin-bottom: 30px;

    &--custom {
      label {
        white-space: nowrap;
        position: absolute;
      }

      display: flex;
      flex-direction: column;

      input,
      .input-date {
        margin: 0;
        margin-top: auto;
        display: block;
        position: relative;
      }

      @include media-breakpoint-down(md) {
        label {
          white-space: normal;
          position: relative;
        }

        display: block;
      }
    }

    option {
      color: #000;
    }

    input,
    textarea,
    select {
      color: #fff;
      border-color: transparent;
      background: rgba(#fff, 0.1);
      border-radius: 10px;
      margin-top: auto;

      &::placeholder {
        color: rgba(#fff, 0.2);
      }

      &:hover {
        border-color: rgba(#fff, 0.3);
      }

      &[disabled] {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &--error {
      input,
      textarea,
      select {
        border-color: transparent !important;
        background: rgba(red, 0.2);
      }

      .checkbox__content,
      .radio__content {
        color: rgba(red, 0.8);

        &:before {
          border-color: rgba(red, 1) !important;
        }
      }
    }
    .messages {
      color: #fff !important;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 30px;
  }

  @include media-breakpoint-down(sm) {
    &__title {
      font-size: 24px;
    }
  }

  @include media-breakpoint-down(xs) {
    .btn {
      font-size: 15px;
      width: 100%;
    }
  }
}

#registration {
  .modal-block {
    max-width: 805px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .field {
    & > label {
      display: flex;
      height: 22px;
      align-items: flex-end;
    }
  }

  .checkbox {
    margin-top: 15px;
    height: auto !important;
  }

  @include media-breakpoint-down(md) {
    padding: 0 30px;

    .modal-block {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;

    .modal-block {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.gender-and-date {
  display: flex;

  .field {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
      flex-grow: 1;
    }
  }
}

.modal-buttons {
  margin: -10px -10px 15px -10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin: 10px;
  }

  &__links {
    display: flex;
    align-items: center;

    & > * {
      margin: 10px;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    text-align: center;

    &__links {
      justify-content: space-between;
      width: 100%;

      & > * {
        &:first-child {
        }

        margin-left: 0;
      }
    }

    .btn {
      width: 100%;
    }
  }
}
